import { useState, useEffect } from "react";
import axios from "axios";

// material
import {

  Button,
  Container,
  FormGroup,

} from "@mui/material";
import Select from "react-select";

// components
import Page from "../../components/Page.js";
import moment from "moment";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';


import { CardBody, Col, Row } from "reactstrap";
import ReactTable from "react-table-v6";

import { Stack, Typography } from "@mui/material";
import ImportFileBusiness  from "./ImportFileBusiness.js";
import Cookies from 'js-cookie';



export default function UserBusiness() {

  const [loading, setLoading] = useState(false);
  const [apprenants, setApprenants] = useState([]);
  const [formations, setFormations] = useState([]);

  const [open, setOpen] = useState(false);
  const token= Cookies.get("tokenAdmin")
  const[listFormations, setListFormations]= useState([])
  const[listDept, setListDept]= useState([])
  
  
  const [selectedFormation, setSelectedFormation] = useState(null);
  const [selectedDept, setSelectedDept] = useState(null);

  const handleFormationChange = (selectedOption) => {
    setSelectedFormation(selectedOption);
  };

  const handleDeptChange = (selectedOption) => {
    setSelectedDept(selectedOption);
  };

  useEffect(() => {
     
      getMoreData();
      getMoreDataDept();
      getApprenants();

    }, []);
    const getMoreData = async () => {
   
      await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/getAllFormationsFormateur`, 
        {
          headers: { Authorization: `Bearer ${token}`, 
          withCredentials: true
        },
      })
        .then(response => {
  setListFormations(response.data.formations)  
  console.log("form",response)

    })
        .catch(error => {
          console.error(error);
        });
    }
  
    const getMoreDataDept = async () => {
   
      await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/getAllDeptFormateur`, 
        {
          headers: { Authorization: `Bearer ${token}`, 
          withCredentials: true
        },
      })
        .then(response => {
          setListDept(response.data.depts)  
          console.log("dept",response)
    })
        .catch(error => {
          console.error(error);
        });
    }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



    


  // ];
  const getApprenants = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        process.env.REACT_APP_URL_BACKEND_DEV + `/getAllApprenantsLists`, 
        {
          headers: { Authorization: `Bearer ${token}`, 
          withCredentials: true
        },
      });
      console.log("rees", res.data);
      if (Array.isArray(res.data.data)) {
        const flattenedData = res.data.data.flatMap(({ formation, listeInscrit }) => {
          return listeInscrit.map(apprenant => ({
            formation: formation.titre,
            nom: apprenant.nom,
            prenom: apprenant.prenom,
            email: apprenant.email,
            dateInscrit :apprenant.createdAt
          }));
        });
        const sortedApprenants = flattenedData.sort((a, b) => new Date(a.dateInscrit) - new Date(b.dateInscrit));
        setApprenants(sortedApprenants);
      } else {
        setApprenants([]); // Fallback to an empty array if data is not an array
      }
    } catch (error) {
      console.error('Error fetching apprenants:', error);
      setApprenants([]); // Fallback to an empty array on error
    } finally {
      setLoading(false);
    }
  };
  console.log("dept",apprenants)
  const columns = [
    {
      Header: "Formation",
      id: "formation",
      accessor: "formation",
      Cell: ({ value }) => {
        return (
          <div>
            <span style={{ wordBreak: "break-all" }}>
              {value}
            </span>
          </div>
        );
      },
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        return row.formation.toLowerCase().includes(filter.value.toLowerCase()); // Partial match
      },
      Filter: ({ filter, onChange }) => {
        return (
          <select
            style={{ width: "100%", fontSize: "1rem" }}
            onChange={(event) => onChange(event.target.value)} // This line updates the filter value
            value={filter ? filter.value : "all"}
          >
            <option value="all">Tous</option> {/* Add an "all" option */}
            {listFormations.map((item) => (
              <option key={item.id} value={item.titre}>
                {item.titre}
              </option>
            ))}
          </select>
        );
      },
    },
    // {
    //   Header: "Departement",
    //   id: "formation",
    //   accessor: "formation",
    //   Cell: ({ value }) => {
    //     return (
    //       <div>
    //         <span style={{ wordBreak: "break-all" }}>
    //           {value}
    //         </span>
    //       </div>
    //     );
    //   },
    //   filterMethod: (filter, row) => {
    //     if (filter.value === "all") {
    //       return true;
    //     }
    //     return row.formation.toLowerCase().includes(filter.value.toLowerCase()); // Partial match
    //   },
    //   Filter: ({ filter, onChange }) => {
    //     return (
    //       <select
    //         style={{ width: "100%", fontSize: "1rem" }}
    //         onChange={(event) => onChange(event.target.value)} // This line updates the filter value
    //         value={filter ? filter.value : "all"}
    //       >
    //         <option value="all">Tous</option> {/* Add an "all" option */}
    //         {listDept.map((item) => (
    //           <option key={item.id} value={item.titre}>
    //             {item.titre}
    //           </option>
    //         ))}
    //       </select>
    //     );
    //   },
    // },
    
    // {
    //   Header: "Formation",
    //   id: "formation",
    //   accessor: "formation",

    //   Cell: ({ value }) => {
    //     return (
    //       <div>
    //         <span style={{ wordBreak: "break-all" }}>
    //           {value}
    //         </span>
    //       </div>
    //     );
    //   },
    //     filterMethod: (filter, row) => {
    //     if (filter.value === "all") {
    //       return true;
    //     }
    //     return row.value === filter.value;
    //   },
    //   Filter: ({ filter, onChange }) => {
    //     return (
    //       <select
    //         style={{ width: "100%", fontSize: "1rem" }}
    //         onChange={(event) => onChange(event.target.value)} // This line updates the filter value
    //         value={filter ? filter.value : "all"}
    //       >
    //         <option value="all">Tous</option> {/* Add an "all" option */}
    //         {listFormations.map((item) => (
    //           <option key={item.id} value={item.titre}>
    //             {item.titre}
    //           </option>
    //         ))}
    //       </select>
    //     );
    //   },
    // },
    {
      Header: "Nom et Prénom",
      id: "nomPrenom",
      accessor: row => `${row.nom} ${row.prenom}`,
      filterMethod: (filter, row) =>
        row.nomPrenom.toLowerCase().includes(filter.value.toLowerCase()),
      Cell: ({ original }) => {
        return (
          <div>
            <span style={{ wordBreak: "break-all" }}>
              {original.nom} {original.prenom}
            </span>
          </div>
        );
      },
    },
        
    
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ original }) => {
        return <div>{original?.email} </div>
      },

    },
    {
      Header: "Date d'inscription",
      accessor: "dateInscrit",
      width: 150,
      Cell: ({ value }) => {
        return (
          <span style={{ wordBreak: "break-all" }}>
            {moment(value).locale("fr").format("DD/MM/YYYY")}
          </span>
        );
      },
      filterMethod: (filter, row) => {
        const date = moment(row[filter.id]).format("DD/MM/YYYY");
        return date.includes(filter.value);
      },
      defaultSortDesc: true,

    },
  ];
  
  

  return (
    <Page title="Dashboard | Fastuz">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
           Inscrits affecté aux formations
          </Typography>
          <Button
            variant="contained"
            onClick={handleClickOpen}          >
           
            Affecter Inscrits
          </Button>
         

        </Stack>

   
        <CardBody>
          <Row>

            <ReactTable
              data={apprenants}
              nextText={"Suivant"}
              previousText={"Précedent"}
              rowsText={"Lignes"}
              columns={columns}
              defaultPageSize={10}
              filterable={true}
              noDataText="No data is available"
              defaultSorted={[{id :"createdAt" , desc : true}]}

            />


          </Row>
        </CardBody>
        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >

        <DialogTitle id="alert-dialog-title">
          {"Ajouter un fichier Excel"}
        </DialogTitle>
      
        <DialogContent>
        <br></br>
            <Select
              placeholder="Choisir Formation"
              value={selectedFormation}
              onChange={handleFormationChange}
              options={listFormations?.map((formation) => ({
                value: formation._id,
                label: formation.titre,
              }))}
            />
            {/* <br />
            <Select
              placeholder="Choisir Département"
              value={selectedDept}
              onChange={handleDeptChange}
              options={listDept?.map((dept) => ({
                value: dept._id,
                label: dept.titre,
              }))}
            /> */}
            <br />
  
          <ImportFileBusiness handleClose={handleClose} selectedFormation={selectedFormation}  selectedDept={selectedDept} getApprenants={getApprenants}/>

        </DialogContent>

      </Dialog>
      </Container>
    </Page>
  );
}
