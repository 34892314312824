import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import moment from "moment";
import SweetAlert from "sweetalert-react/lib/SweetAlert";
import { DeleteCategorieAPI, DeleteTypeFormationAPI } from "../../utils/api";
import { getPartners } from "../../redux/partnerSlice";
import { Icon } from "@iconify/react";
import plusFill from '@iconify/icons-eva/plus-fill';
import { useNavigate } from 'react-router-dom';
import { Button, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DialogContentText from '@mui/material/DialogContentText';
import ReactTable from 'react-table-v6';
import { Link } from "react-router-dom";
import ReactTableUI from 'react-table-ui';
import Swal from "sweetalert2";
import AddCategorie from "./NouveauTypeFormation"
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import axios from "axios";
import UpdateCategorie from "./DetailsTypeFormation"
function Partenaire() {
  let [show, setShow] = useState(false);
  const [page, setPage] = useState(0);
  const pageSize = 10;
  const [partners, setPartners] = useState([])
  const [selectedContact, setSelectedContact] = useState({});

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [update, setUpdate] = React.useState(false);

  const handleClickOpenUpdate = (partner) => {
    setSelectedContact(partner);

    setUpdate(true);
  };

  const handleCloseUpdate = () => {
    setUpdate(false);
  };
  let [modal, setModal] = useState(false);
  let [type, setType] = useState("");
  const toggleModal = () => setModal(!modal);
  let [selectedPartner, setSelectedPartner] = useState({});
  const listPartners = useSelector((state) => state.reglage.listPartners);
  const [loading, setLoading] = React.useState(false);
  console.log(partners)
  console.log(listPartners)
  const pageCount = Math.ceil(partners.length / pageSize);
  const currentPageData = partners.slice(page * pageSize, (page + 1) * pageSize);
  const dispatch = useDispatch();
  const navigate = useNavigate();



  const statsColumns = [

    {
      Header: "Titre",
      accessor: "titre",
      style: { wordBreak: "break-all" },
    },

    {
      Header: "Date de création",
      accessor: "createdAt",

      Cell: ({ value }) => {
        return (
          <span style={{ wordBreak: "break-all" }}>
            {moment(value).locale("fr").format("DD/MM/YYYY")}
          </span>
        );
      },
      filterMethod: (filter, row) => {
        const date = moment(row[filter.id]).format("DD/MM/YYYY");
        return date.includes(filter.value);
      },
    },
    {
      Header: "Actions",
      style: { textAlign: "center" },
      Cell: ({ original }) => {
        return (
          <div className="d-block w-100 text-center">
            <LoadingButton
              onClick={() => handleClickOpenUpdate(original)}                    >
              Modifier
            </LoadingButton>



            <LoadingButton


              onClick={async () => {

                Swal.fire({
                  title: "Êtes-vous sûr de vouloir supprimer ?",
                  icon: "warning",
                  showCancelButton: true,
                  cancelButtonText: "Annuler",

                  confirmButtonColor: "#0d3077",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Supprimer type formation!",
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    await DeleteTypeFormationAPI(original?._id)
                    getPartners()
                      // dispatch(getListeOfTeachers());
                      // dispatch(getAllFormationsFormateur())                             
                      .then(async (res) => {
                        if (res?.status === 200) {

                          Swal.fire("Type formation supprimé!");
                          // await getData()
                        } else {
                          Swal.fire(
                            "Type formation supprimé!"
                          );
                          getPartners();
                        }
                      });
                  }
                });
              }}
            >
              Supprimer
            </LoadingButton>
          </div>
        );
      },
    },

  ];

  const getPartners = async () => {
    setLoading(true);
    const res = await axios.get(
      process.env.REACT_APP_URL_BACKEND_DEV + `/typeFormation`
    );
    console.log(res)
    setPartners(res.data.categorie);
    setLoading(false);
  };
  useEffect(() => {

    getPartners();
  }, []);


  return (
    <div>
      <Card className="card-hover-shadow-2x mb-3">

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Type formation
          </Typography>
          <Button
            variant="contained"
            onClick={handleClickOpen}
            startIcon={<Icon icon={plusFill} />}
          >
            Ajouter type formation
          </Button>
        </Stack>
        <CardBody>
          <Row>

            <ReactTable
              data={partners}
              nextText={"Suivant"}
              previousText={"Précedent"}
              rowsText={"Lignes"}
              columns={statsColumns}
              defaultPageSize={10}
              filterable={true}
              noDataText="No data is available"
            />

          </Row>
        </CardBody>
      </Card>


      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Ajouter type formation"}
        </DialogTitle>
        <DialogContent>
          <AddCategorie handleClose={handleClose} getPartners={getPartners} />
        </DialogContent>

      </Dialog>

      <Dialog
        open={update}
        onClose={handleCloseUpdate}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Modifier type reclamation"}
        </DialogTitle>
        <DialogContent>
          <UpdateCategorie selectedContact={selectedContact} handleCloseUpdate={handleCloseUpdate} getPartners={getPartners} />
        </DialogContent>

      </Dialog>

    </div>
  );
}

export default Partenaire;


