import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { loginFormateurAPI, getAllTeachersAPI, getFormateurByToken } from "../utils/api";

const initialState = {
  CurrentFormateur: {},
  status: "idle",
  statusLogin: "noLogin",
  messageLogin: "",
  formateurs: [],
};



export const LoginFormateurMethode = createAsyncThunk(
  "Auth/LoginMethode",
  async (formateur) => {
    try {
      const response = await loginFormateurAPI(formateur);

      return response.data;
    } catch (err) {
    }

  }
);


export const getCurrentFormateurByToken = createAsyncThunk(
  "Auth/getCurrentFormateurByToken",
  async (token) => {
    try {
      const response = await getFormateurByToken(token);

      return response.data.formateur;
    } catch (err) {
    }

  }
);
export const getListeOfTeachers = createAsyncThunk(
  "Auth/getListeOfTeachers",
  async () => {
    try {
      const response = await getAllTeachersAPI();

      return response.data.formateurs;
    } catch (err) {
    }

  }
);
export const authSlice = createSlice({
  name: "Auth",
  initialState,
  reducers: {},

  extraReducers: {
   
    [LoginFormateurMethode.pending]: (state, action) => {
      state.status = "loading";
    },
    [LoginFormateurMethode.fulfilled]: (state, action) => {
      state.status = "sucess";

      if (action.payload.statue === false) {
        state.messageLogin = action.payload.message;
      } else {
        state.statusLogin = "Logined";
        state.CurrentFormateur = action.payload.user;
      }
    },
    [getCurrentFormateurByToken.pending]: (state, action) => {
      state.status = "loading";
    },
    [getCurrentFormateurByToken.fulfilled]: (state, action) => {
      state.status = "sucess";
      state.statusLogin = "Logined";
      state.CurrentFormateur = action.payload;
    },
   
    [getListeOfTeachers.pending]: (state, action) => {
      state.status = "loading";
    },
    [getListeOfTeachers.fulfilled]: (state, action) => {
      state.status = "sucess";

      state.teachers = action.payload;
    },
  },
});

export default authSlice.reducer;
