import * as React from 'react';
import Cookies from 'js-cookie';
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios"
import { CardBody, Row } from "reactstrap";
import ReactTable from "react-table-v6";
import Page from "../../components/Page";
import { Stack } from "@mui/material";
import {

  Container

} from "@mui/material";

export default function BasicTable() {
  const [apprenants, setApprenants] = useState([])
  const [apprenantsPres, setApprenantsPres] = useState([])
  const [formation, setFormation] = useState([]);

  const token = Cookies.get("tokenAdmin")
  const [loading, setLoading] = useState(false);
  const { idFormation } = useParams();

  useEffect(() => {

    getApprenants();
  }, []);
  const getApprenants = async () => {
    setLoading(true);
    const res = await axios.get(
      process.env.REACT_APP_URL_BACKEND_DEV + `/getAllApprenantsFormateur/${idFormation}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        withCredentials: true
      },
    }
    );
    setApprenants(res.data.EtudiantEnrolled);
    setApprenantsPres(res.data.EtudiantEnrolledPres)

  };

  const getData = async () => {
    if (idFormation) {
      setLoading(true);
      try {
        const res = await axios.get(
          process.env.REACT_APP_URL_BACKEND_DEV + `/formation/${idFormation}`
        );

        if (res.status === 200) {
          setFormation(res.data);

        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle the error appropriately, e.g., show an error message to the user
      }
    }
  };

  useEffect(() => {
    getData();
  }, [idFormation]);

  const columns = [
    {
      Header: "Nom et Prénom",
      id: "nomPrenom",
      accessor: row => `${row.nom} ${row.prenom}`,
      filterMethod: (filter, row) =>
        row.nomPrenom.toLowerCase().includes(filter.value.toLowerCase()),
      Cell: ({ original }) => {
        return (
          <span style={{ wordBreak: "break-all" }}>
            {original.nom} {original.prenom}
          </span>
        );
      },
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ original }) => {
        return <div>{original?.email} </div>
      },

    },
    {
      Header: "Status du compte",
      accessor: "active",
      Cell: ({ original }) => {
        return <div>{original?.active ? "True" : "False"} </div>
      },
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        if (filter.value === "true") {
          return row[filter.id] === true;
        }
        if (filter.value === "false") {
          return row[filter.id] === false;
        }
        return false;
      },
      Filter: ({ filter, onChange }) => {
        return (
          <select
            style={{ width: "100%", fontSize: "1rem" }}

            onChange={(event) => onChange(event.target.value)}
            value={filter ? filter.value : "all"}
          >
            <option value="all">Filtrer</option>
            <option value="true">Compte activé</option>
            <option value="false">Compte désactivé</option>
          </select>
        );
      },
    }


  ];

  return (
    <Page title="Dashboard | Fastuz">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >


        </Stack>


        <CardBody>
          <Row>

            <ReactTable
              data={formation.typeFormation?.titre === "En ligne" ? apprenants : apprenantsPres}
              nextText={"Suivant"}
              previousText={"Précedent"}
              rowsText={"Lignes"}
              columns={columns}
              defaultPageSize={10}
              filterable={true}
              noDataText="No data is available"
            />
          </Row>
        </CardBody>
      </Container>
    </Page>
  );
}

