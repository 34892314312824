import moment from "moment";
import {
  Stack,
  Container,
  Typography,
} from '@mui/material';
import Page from '../../components/Page';
import axios from 'axios';
import { useEffect, useState ,useRef} from "react";
import ReactTable from 'react-table-v6'
import "react-table-v6/react-table.css";

export default function Formations() {
  const [scores, setScores]= useState([])
  useEffect(() => {
    const getData = async () => {
      const res = await axios.get(
        process.env.REACT_APP_URL_BACKEND_DEV + `/getBestScore`
      );
      setScores(res.data.result);

    };
    getData();
    }, []);
console.log("scoressssss", scores)
scores.sort((a, b) => {
  return parseFloat(b.score) - parseFloat(a.score);
});
  const columns = [
    {
      Header: "Apprenants",
      id: "nomPrenom",
      accessor: row => `${row.etudiant?.nom} ${row.etudiant?.prenom}`,
      filterMethod: (filter, row) =>
        row.nomPrenom.toLowerCase().includes(filter.value.toLowerCase()),
      width:160,
      Cell: ({original}) => {
        return <div>{original?.etudiant?.nom}  {original?.etudiant?.prenom}</div>
      },
    },
    {
      Header: "Formations",
      accessor: "formation.titre",
      filterMethod: (filter, row) =>
    row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
      Cell: ({original}) => {
        return <div style={{ whiteSpace: "pre-wrap"}}>{original?.quizz?.formation_id?.titre} </div>
      },
    
    },

    {
      Header: "Test blancs",
      accessor:"quizz.name",
      filterMethod: (filter, row) =>
    row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
      Cell: ({original}) => {
        return <div>{original?.quizz?.name}</div>
      },
    },
    {
      Header: "Scores",
      accessor:"score",
      width:100,
      Cell: ({original}) => {
        return <div>{parseFloat(original?.score).toFixed(2)} %</div>
      },
    },
    {
      Header: "Date de passage du test",
      accessor:"quizz.created",
      Cell: ({ original }) => {
        return (
          <span style={{ wordBreak: "break-all" }}>
            {moment(original.createdAt).format("DD/MM/YYYY : HH:mm")}
          </span>
        );
      },
      filterMethod: (filter, row) => {
        const date = moment(row[filter.id]).format("DD/MM/YYYY : HH:mm");
        return date.includes(filter.value);
      },
    },
 

  ];


  return (
    <Page title="Dashboard | Fastuz">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Scores des apprenants
          </Typography>
        </Stack>

        <ReactTable
              data={scores}
              nextText={"Suivant"}
              previousText={"Précedent"}
              rowsText={"Lignes"}
              columns={columns}
              defaultPageSize={10}
              filterable={true}
              noDataText="No data is available"
            />
   
   
      </Container>

    </Page>

  );
}