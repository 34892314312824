import {
  Stack,
  Container,
  Typography,
} from '@mui/material';
import {
  getAllContacts

} from "../../redux/contactSlice";
import Page from '../../components/Page';
import axios from 'axios';
import moment from "moment";

import { useNavigate } from 'react-router-dom';

import { useEffect, useState } from "react";
import ReactTable from 'react-table-v6'
import { useDispatch, useSelector } from "react-redux";
import { SupprimerContactAPI } from '../../utils/api';
import "react-table-v6/react-table.css";
import Swal from "sweetalert2";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from '@mui/lab';
import DetailsContact from "./DetailsContact"
export default function Formations() {
  const [page, setPage] = useState(0);
  const pageSize = 10;
  const [review, setReview] = useState([])
  const [selectedContact, setSelectedContact] = useState({});
  const [typeRec, setTypeRec] =  useState([])
  const [contacts, setContacts] =  useState([])

  const [open, setOpen] = useState(false);

  const getTyperec = async () => {
    const res = await axios.get(
      process.env.REACT_APP_URL_BACKEND_DEV +`/TypeRec`
    );
    setTypeRec(res.data.categorie);
  };
  useEffect(() => {
    getTyperec();
    getContact();
  }, []);

  const getContact = async () => {
    const res = await axios.get(
      process.env.REACT_APP_URL_BACKEND_DEV +`/contacts`
    );
    setContacts(res.data.Contacts);
  };


  console.log("setContacts",contacts)

  const handleClickOpen = (contact) => {
    setSelectedContact(contact);
    setOpen(true);

  };

  const handleClose = () => {
    setOpen(false);
  };

  const listContacts = useSelector(
    (state) => state.contact.listecontacts
  );
  const dispatch = useDispatch();
console.log("listContacts",listContacts)



  const columns = [
    {
      Header: "Apprenants",
      id: "nomPrenom",
      accessor: row => `${row.firstname} ${row.lastname}`,
      filterMethod: (filter, row) =>
        row.nomPrenom.toLowerCase().includes(filter.value.toLowerCase()),
      width: 160,
      Cell: ({ original }) => {
        return <div>{original?.firstname}  {original?.lastname}</div>
      },
    },
    {
      Header: "Numero",
      accessor: "phone",
      width: 150,

    },
    {
      Header: "Message",
      accessor: "message",
      filterMethod: (filter, row) =>
        row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
      Cell: ({ original }) => {
        return <div style={{ whiteSpace: "pre-wrap" }}>{original?.message} </div>
      },

    },
    {
      Header: "Date",
      accessor: "createdAt",
      width: 150,
      Cell: ({ value }) => {
        return (
          <span style={{ wordBreak: "break-all" }}>
            {moment(value).locale("fr").format("DD/MM/YYYY")}
          </span>
        );
      },
      filterMethod: (filter, row) => {
        const date = moment(row[filter.id]).format("DD/MM/YYYY");
        return date.includes(filter.value);
      },
      defaultSortDesc: true,
    },
    {
      Header: "Type réclamation",
      accessor: "reclamation",
      Cell: ({ original }) => {
        return <div>{original?.reclamation.titre }</div>;
      },
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        return row.reclamation.titre === filter.value;
      },
      Filter: ({ filter, onChange }) => {
        return (
          <select
            style={{ width: "100%", fontSize: "1rem" }}
            onChange={(event) => onChange(event.target.value)} // This line updates the filter value
            value={filter ? filter.value : "all"}
          >
            <option value="all">Tous</option> {/* Add an "all" option */}
            {typeRec.map((item) => (
              <option key={item.id} value={item.titre}>
                {item.titre}
              </option>
            ))}
          </select>
        );
      },
    },
    
    {
      Header: "Actions",
      width: 200,
      Cell: ({ original }) => {
        return <div>

          <LoadingButton

            onClick={async () => {

              Swal.fire({
                title: "Êtes-vous sûr de vouloir supprimer ce contact?",
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: "Annuler",

                confirmButtonColor: "#0d3077",
                cancelButtonColor: "#d33",
                confirmButtonText: "Supprimer contact!",
              }).then(async (result) => {
                if (result.isConfirmed) {
                  await SupprimerContactAPI(original?._id);

                  dispatch(getContact())
                    .then(async (res) => {
                      if (res.status === 200) {

                        Swal.fire("Contact supprimé!");

                      } else {
                        Swal.fire(
                          "Contact supprimé!"
                        );
                      }
                    });
                }
              });




            }}
          >
            Supprimer
          </LoadingButton>

          <LoadingButton
            onClick={() => handleClickOpen(original)}
          >
            Details
          </LoadingButton>

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Détails du contact"}
              <br />
            </DialogTitle>
            <DialogContent>
              <DetailsContact selectedContact={selectedContact} />
            </DialogContent>

          </Dialog>

        </div>
      },
    },


  ];



  return (
    <Page title="Dashboard | Fastuz">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Contacts
          </Typography>

        </Stack>
        <ReactTable
          data={contacts}
          nextText={"Suivant"}
          previousText={"Précedent"}
          rowsText={"Lignes"}
          columns={columns}
          defaultPageSize={10}
          filterable={true}
          noDataText="No data is available"
        />



      </Container>

    </Page>

  );
}


