// import plusFill from '@iconify/icons-eva/plus-fill';
// import {
//   Stack,
//   Button,
//   Container,
//   Typography,
// } from '@mui/material';
// import Page from '../../components/Page';
// import { useNavigate } from 'react-router-dom';
// import moment from "moment";
// import { useEffect, useState ,useRef} from "react";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   getAllFormations

// } from "../../redux/formationSlice";
// import { Link } from "react-router-dom";
// import "react-table-v6/react-table.css";
// import Swal from "sweetalert2";
// import "./style.css"
// import ReactTable from 'react-table-v6'
// import { Icon } from '@iconify/react';
// import { SupprimerFormationAPI, desactiveFormation } from '../../utils/api';
// import { LoadingButton } from '@mui/lab';

// export default function Formations() {
  
//   const [currentPageData, setCurrentPageData] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const listFormations = useSelector((state) => state.formations.listeFormations);

//   const pageSize = 5;
  
//   useEffect(() => {
//     const startIndex = (currentPage - 1) * pageSize;
//     const endIndex = Math.min(startIndex + pageSize, listFormations?.length);
//     setCurrentPageData(listFormations?.slice(startIndex, endIndex));
//   }, [currentPage, listFormations, pageSize]);
//   console.log("listFormations",listFormations)
//   const dispatch = useDispatch();
//   const generateOptions = (formations) => {
//     // Get unique typeFormation values
//     const uniqueTypeFormations = [...new Set(formations.map(item => item.typeFormation))];
    
//     // Generate options based on unique typeFormation values
//     const options = uniqueTypeFormations.map((typeFormation) => (
//       <option key={typeFormation} value={typeFormation}>
//         {typeFormation}
//       </option>
//     ));
    
//     return options;
//   };
//   const columns = [
//     {
//       Header: "Titre formation",
//       accessor: "titre",
//       width: 270,
//       filterMethod: (filter, row) =>
//     row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
//       Cell: ({ original }) => {
//         return (
//           <span style={{ wordBreak: "break-all" }}>
//             <Link to={"/contenuformations/" + original?._id}>
       
//                {original?.titre}
//                </Link>
//           </span>
//         );
//       },
//     },
//     {
//       Header: "Date de création",
//       accessor: "createdAt",
//       width: 150,
//       Cell: ({ value }) => {
//         return (
//           <span style={{ wordBreak: "break-all" }}>
//             {moment(value).locale("fr").format("DD/MM/YYYY")}
//           </span>
//         );
//       },
//       filterMethod: (filter, row) => {
//         const date = moment(row[filter.id]).format("DD/MM/YYYY");
//         return date.includes(filter.value);
//       },
//       defaultSortDesc: true,

//     },
//     {
//       Header: "Catégorie",
//       width: 150,
//       accessor: "categorie",
//       filterMethod: (filter, row) => {
//         const categorieName = row[filter.id]?.nom || ""; // Access the "nom" property of the "categorie" object
//         return categorieName.toLowerCase().includes(filter.value.toLowerCase());
//       },
//       Cell: ({ original }) => {
//         return <div style={{ display: "flex", justifyContent: "center" }}>
//   {original?.categorie?.nom ? (
//     <div>{original?.categorie?.nom}</div>
//   ) : (
//     <div>Pas de catégorie</div>
//   )}
// </div>
//       },
//     },
//     {
//       Header: "Type Formation",
//       accessor: "typeFormation",
//       Cell: ({ original }) => {
//         return <div>{original?.typeFormation }</div>;
//       },
//       filterMethod: (filter, row) => {
//         if (filter.value === "all") {
//           return true;
//         }
//         return row.typeFormation === filter.value;
//       },
//       Filter: ({ filter, onChange }) => {
//         return (
//           <select
//     style={{ width: "100%", fontSize: "1rem" }}
//     onChange={(event) => onChange(event.target.value)}
//     value={filter ? filter.value : "all"}
//   >
//     <option value="all">Tous</option>
//     {generateOptions(listFormations)}
//   </select>
//         );
//       },
//     },
 
//     {
//       Header: "Prix",
//       accessor: "prix",
//       Cell: ({original}) => {
//         return <div style={{ display: "flex", justifyContent: "center" }}>
//         {original.prix > 0 ? (
//           <div>{original.prix} TND</div>
//         ) : (
//           <div>Free </div>
//         )}
//         </div>
//       },
//     }, 
//     {
//       Header: "Prix externe",
//       accessor: "prixExterne",
//       width: 150,
//       headerProps: {
//         style: {
//           textAlign: "center"
//         }
//       },
     
//       Cell: ({original}) => {
//         return <div>{original?.prixExterne} {original?.devise} </div>
//       },
//     },
//     {
//       Header: "Nom du formateur",
//       width: 180,
//       id: "nomPrenom",
//   accessor: row => `${row.formateur?.nom} ${row.formateur?.prenom}`,
//   filterMethod: (filter, row) =>
//     row.nomPrenom.toLowerCase().includes(filter.value.toLowerCase()),
//       Cell: ({ original }) => {
//         return (
//           <span style={{ wordBreak: "break-all" }}>
//             {original.formateur?.nom} {original.formateur?.prenom}
//           </span>
//         );
//       },
//     },
//     {
//       Header: "Apprenants",
//       accessor: "enrolledBy",
//       width: 150,

//       Cell: ({ original }) => {
//         return <div style={{ display: "flex", justifyContent: "center" }}>
//   {original.enrolledBy.length > 0 ? (
//     <div>{original?.enrolledBy.length}</div>
//   ) : (
//     <div>Pas d'apprenants</div>
//   )}
// </div>
//       },
//     },

//       {
//         Header: "Actions",
//         width:270,
//         Cell: ({original}) => {
//           return <div>  
//               <Link to={"/detailFormation/" + original?._id}>  
//              <LoadingButton>
//             Details Formations
//           </LoadingButton> </Link>            
//             {/*
            
          

//           <LoadingButton
                  
//                   onClick={ async() => {

//                     Swal.fire({
//                           title: "Êtes-vous sûr de vouloir supprimer cette formation?",
//                           icon: "warning",
//                           showCancelButton: true,
//                           cancelButtonText: "Annuler",

//                           confirmButtonColor: "#0d3077",
//                           cancelButtonColor: "#d33",
//                           confirmButtonText: "Supprimer formation!",
//                         }).then(async (result) => {
//                           if (result.isConfirmed) {
//                             await     SupprimerFormationAPI(original?._id);
                    
//                             dispatch(getAllFormations())                             
//                             .then(async (res) => {
//                                 if (res.status === 200) {
                    
//                                   Swal.fire("Formation supprimé!");
                    
//                                 } else {
//                                   Swal.fire(
//                                     "Formation supprimé!"
//                                   );
//                                 }
//                               });
//                           }
//                         });

         

//                   }}
//                 >
//                   Supprimer 
//                 </LoadingButton> */}
//                 <LoadingButton
                  
//                   onClick={ async() => {

//                     Swal.fire({
//                           title: `Voulez vous vraiment ${original?.publier ? " dépublier" : "publier"} cette formation`,
//                           icon: "warning",
//                           showCancelButton: true,
//                           cancelButtonText: "Annuler",

//                           confirmButtonColor: "#0d3077",
//                           cancelButtonColor: "#d33",
//                           confirmButtonText: `${original?.publier ? " dépublier" : "publier"} formation!`,
//                         }).then(async (result) => {
//                           if (result.isConfirmed) {
//                             await     desactiveFormation(original?._id);
                    
//                             dispatch(getAllFormations())                             
//                             .then(async (res) => {
//                                 if (res.status === 200) {
                    
//                                   Swal.fire(`Formation ${original?.publier ? " depubliée" : "publiée"}!`, "Succès");
                    
//                                 } else {
//                                   Swal.fire(
//                                     `Formation ${original?.publier ? " depubliée" : "publiée"}!`, 
//                                   );
//                                 }
//                               });
//                           }
//                         });

         

//                   }}
//                 >

//                 {original?.publier ?  "dépublier" : "publier" } 
//                 </LoadingButton>
//           </div>
//         },
//       },

    

//   ];

// const navigate = useNavigate();

// useEffect(() => {
//     dispatch(getAllFormations());
//     }, [dispatch]);
//   return (
//     <Page title="Dashboard | Fastuz">
//       <Container>
//         <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
//           <Typography variant="h4" gutterBottom>
//             Formations
//           </Typography>
//           {/* <Button
//             variant="contained"
//             onClick={() => navigate("/AddFormations")}
//             startIcon={<Icon icon={plusFill} />}
//           >
//             Ajouter Formation
//           </Button> */}
//         </Stack>
//         <ReactTable
//     data={listFormations}
//     nextText={"Suivant"}
//     previousText={"Précedent"}
//     rowsText={"Lignes"}
//     columns={columns}
//     defaultPageSize={10}
//     filterable={true}
//     noDataText="No data is available"
//     defaultSorted={[{id :"createdAt" , desc : true}]}

// />
//       </Container>

//     </Page>

//   );
// }



import plusFill from '@iconify/icons-eva/plus-fill';
import {
  Stack,
  Button,
  Container,
  Typography,
} from '@mui/material';
import Page from '../../components/Page';
import { useNavigate } from 'react-router-dom';
import moment from "moment";
import { useEffect, useState ,useRef} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllFormations

} from "../../redux/formationSlice";
import { Link } from "react-router-dom";
import "react-table-v6/react-table.css";
import Swal from "sweetalert2";
import "./style.css"
import ReactTable from 'react-table-v6'
import { Icon } from '@iconify/react';
import { SupprimerFormationAPI, desactiveFormation } from '../../utils/api';
import { LoadingButton } from '@mui/lab';

export default function Formations() {
  
  const [currentPageData, setCurrentPageData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const listFormations = useSelector((state) => state.formations.listeFormations);

  const pageSize = 5;
  
  useEffect(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize, listFormations?.length);
    setCurrentPageData(listFormations?.slice(startIndex, endIndex));
  }, [currentPage, listFormations, pageSize]);
  console.log("listFormations",listFormations)
  const dispatch = useDispatch();
 
  const columns = [
    {
      Header: "Titre formation",
      accessor: "titre",
      width: 270,
      filterMethod: (filter, row) =>
    row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
      Cell: ({ original }) => {
        return (
          <span style={{ wordBreak: "break-all" }}>
            <Link to={"/contenuformations/" + original?._id}>
       
               {original?.titre}
               </Link>
          </span>
        );
      },
    },
    {
      Header: "Date de création",
      accessor: "createdAt",
      width: 150,
      Cell: ({ value }) => {
        return (
          <span style={{ wordBreak: "break-all" }}>
            {moment(value).locale("fr").format("DD/MM/YYYY")}
          </span>
        );
      },
      filterMethod: (filter, row) => {
        const date = moment(row[filter.id]).format("DD/MM/YYYY");
        return date.includes(filter.value);
      },
      defaultSortDesc: true,

    },
    {
      Header: "Catégorie",
      width: 150,
      accessor: "categorie",
      filterMethod: (filter, row) => {
        const categorieName = row[filter.id]?.nom || ""; // Access the "nom" property of the "categorie" object
        return categorieName.toLowerCase().includes(filter.value.toLowerCase());
      },
      Cell: ({ original }) => {
        return <div style={{ display: "flex", justifyContent: "center" }}>
  {original?.categorie?.nom ? (
    <div>{original?.categorie?.nom}</div>
  ) : (
    <div>Pas de catégorie</div>
  )}
</div>
      },
    },

    {
      Header: "Type Formation",
      width: 150,
      accessor: "typeFormation",
      filterMethod: (filter, row) => {
        const TypeFormatinName = row[filter.id]?.titre || ""; // Access the "nom" property of the "categorie" object
        return TypeFormatinName.toLowerCase().includes(filter.value.toLowerCase());
      },
      Cell: ({ original }) => {
        return <div style={{ display: "flex", justifyContent: "center" }}>
  {original?.typeFormation?.titre ? (
    <div>{original?.typeFormation?.titre}</div>
  ) : (
""  )}
</div>
      },
    },
  //   {
  //     Header: "Type Formation",
  //     accessor: "typeFormation",
  //     Cell: ({ original }) => {
  //       return <div>{original?.typeFormation }</div>;
  //     },
  //     filterMethod: (filter, row) => {
  //       if (filter.value === "all") {
  //         return true;
  //       }
  //       return row.typeFormation === filter.value;
  //     },
  //     Filter: ({ filter, onChange }) => {
  //       return (
  //         <select
  //   style={{ width: "100%", fontSize: "1rem" }}
  //   onChange={(event) => onChange(event.target.value)}
  //   value={filter ? filter.value : "all"}
  // >
  //   <option value="all">Tous</option>
  //   {generateOptions(listFormations)}
  // </select>
  //       );
  //     },
  //   },
 
    {
      Header: "Prix",
      accessor: "prix",
      Cell: ({original}) => {
        return <div style={{ display: "flex", justifyContent: "center" }}>
        {original.prix > 0 ? (
          <div>{original.prix} TND</div>
        ) : (
          <div>Free </div>
        )}
        </div>
      },
    }, 
    {
      Header: "Prix externe",
      accessor: "prixExterne",
      width: 150,
      headerProps: {
        style: {
          textAlign: "center"
        }
      },
     
      Cell: ({original}) => {
        return <div>{original?.prixExterne} {original?.devise} </div>
      },
    },
    {
      Header: "Nom du formateur",
      width: 180,
      id: "nomPrenom",
  accessor: row => `${row.formateur?.nom} ${row.formateur?.prenom}`,
  filterMethod: (filter, row) =>
    row.nomPrenom.toLowerCase().includes(filter.value.toLowerCase()),
      Cell: ({ original }) => {
        return (
          <span style={{ wordBreak: "break-all" }}>
            {original.formateur?.nom} {original.formateur?.prenom}
          </span>
        );
      },
    },
    {
      Header: "Apprenants",
      accessor: "enrolledBy",
      width: 150,

      Cell: ({ original }) => {
        return <div style={{ display: "flex", justifyContent: "center" }}>
  {original.enrolledBy.length > 0 ? (
    <div>{original?.enrolledBy.length}</div>
  ) : (
    <div>Pas d'apprenants</div>
  )}
</div>
      },
    },

      {
        Header: "Actions",
        width:350,
        Cell: ({original}) => {
          return <div>              
            <Link to={"/detailFormation/" + original?._id}>  
             <LoadingButton>
            Details Formations
          </LoadingButton> </Link>

          <LoadingButton
                  
                  onClick={ async() => {

                    Swal.fire({
                          title: "Êtes-vous sûr de vouloir supprimer cette formation?",
                          icon: "warning",
                          showCancelButton: true,
                          cancelButtonText: "Annuler",

                          confirmButtonColor: "#0d3077",
                          cancelButtonColor: "#d33",
                          confirmButtonText: "Supprimer formation!",
                        }).then(async (result) => {
                          if (result.isConfirmed) {
                            await     SupprimerFormationAPI(original?._id);
                    
                            dispatch(getAllFormations())                             
                            .then(async (res) => {
                                if (res.status === 200) {
                    
                                  Swal.fire("Formation supprimé!");
                    
                                } else {
                                  Swal.fire(
                                    "Formation supprimé!"
                                  );
                                }
                              });
                          }
                        });

         

                  }}
                >
                  Supprimer 
                </LoadingButton>
                <LoadingButton
                  
                  onClick={ async() => {

                    Swal.fire({
                          title: `Voulez vous vraiment ${original?.publier ? " dépublier" : "publier"} cette formation`,
                          icon: "warning",
                          showCancelButton: true,
                          cancelButtonText: "Annuler",

                          confirmButtonColor: "#0d3077",
                          cancelButtonColor: "#d33",
                          confirmButtonText: `${original?.publier ? " dépublier" : "publier"} formation!`,
                        }).then(async (result) => {
                          if (result.isConfirmed) {
                            await     desactiveFormation(original?._id);
                    
                            dispatch(getAllFormations())                             
                            .then(async (res) => {
                                if (res.status === 200) {
                    
                                  Swal.fire(`Formation ${original?.publier ? " depubliée" : "publiée"}!`, "Succès");
                    
                                } else {
                                  Swal.fire(
                                    `Formation ${original?.publier ? " depubliée" : "publiée"}!`, 
                                  );
                                }
                              });
                          }
                        });

         

                  }}
                >

                {original?.publier ?  "dépublier" : "publier" } 
                </LoadingButton>
          </div>
        },
      },

    

  ];

const navigate = useNavigate();

useEffect(() => {
    dispatch(getAllFormations());
    }, [dispatch]);
  return (
    <Page title="Dashboard | Fastuz">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Formations
          </Typography>
          <Button
            variant="contained"
            onClick={() => navigate("/AddFormations")}
            startIcon={<Icon icon={plusFill} />}
          >
            Ajouter Formation
          </Button>
        </Stack>
        <ReactTable
    data={listFormations}
    nextText={"Suivant"}
    previousText={"Précedent"}
    rowsText={"Lignes"}
    columns={columns}
    defaultPageSize={10}
    filterable={true}
    noDataText="No data is available"
    defaultSorted={[{id :"createdAt" , desc : true}]}

/>
      </Container>

    </Page>

  );
}






