// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.messenger {
  display: grid;
  width: 100%;
  height: 70vh;
  background: #eeeef1;

  grid-template-columns: 350px auto;
  grid-template-rows: 60px auto 60px;
  grid-column-gap: 1px;
  grid-row-gap: 1px;
}

.container {
  padding: 10px;
}

.scrollable {
  position: relative;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.sidebar {
  background: white;
  grid-row-start: 1;
  grid-row-end: span 3;
}

.content {
  background: white;
  grid-row-start: 1;
  grid-row-end: span 3;
}

.footer {
  grid-column-start: 2;
  background: white;
}
/* .test {
  background: white;
  grid-row-start: 0;
  grid-row-end: span 3;
} */`, "",{"version":3,"sources":["webpack://./src/pages/Messenger.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,YAAY;EACZ,mBAAmB;;EAEnB,iCAAiC;EACjC,kCAAkC;EAClC,oBAAoB;EACpB,iBAAiB;AACnB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,iCAAiC;AACnC;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,oBAAoB;EACpB,iBAAiB;AACnB;AACA;;;;GAIG","sourcesContent":[".messenger {\n  display: grid;\n  width: 100%;\n  height: 70vh;\n  background: #eeeef1;\n\n  grid-template-columns: 350px auto;\n  grid-template-rows: 60px auto 60px;\n  grid-column-gap: 1px;\n  grid-row-gap: 1px;\n}\n\n.container {\n  padding: 10px;\n}\n\n.scrollable {\n  position: relative;\n  overflow-y: scroll;\n  -webkit-overflow-scrolling: touch;\n}\n\n.sidebar {\n  background: white;\n  grid-row-start: 1;\n  grid-row-end: span 3;\n}\n\n.content {\n  background: white;\n  grid-row-start: 1;\n  grid-row-end: span 3;\n}\n\n.footer {\n  grid-column-start: 2;\n  background: white;\n}\n/* .test {\n  background: white;\n  grid-row-start: 0;\n  grid-row-end: span 3;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
