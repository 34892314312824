// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .pagination button {
    margin: 0 10px;
  }

  .react-table-pagination {
    display: none;
  }

  .rt-pagination {
    display: none;
  }

  .rt-pagination .-pageInfo {
    display: none;
 }`, "",{"version":3,"sources":["webpack://./src/pages/Formations/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,aAAa;CAChB","sourcesContent":[".pagination {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  \n  .pagination button {\n    margin: 0 10px;\n  }\n\n  .react-table-pagination {\n    display: none;\n  }\n\n  .rt-pagination {\n    display: none;\n  }\n\n  .rt-pagination .-pageInfo {\n    display: none;\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
