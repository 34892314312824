/* eslint-disable no-sparse-arrays */
import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import settings2fill from '@iconify/icons-eva/settings-2-fill';
import textfill from '@iconify/icons-eva/text-fill';
import giftfill from '@iconify/icons-eva/gift-fill';

import personaddfill from '@iconify/icons-eva/person-add-fill';
import categorie from '@iconify/icons-eva/plus-circle-fill';

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfigBusiness = [
 
  {
    title: 'Profil',
    path: '/profile',
    icon: getIcon(settings2fill)
  },
  {
    title: 'Formations',
    path: '/business/formations',
    icon: getIcon(textfill)
  },
  {
    title: 'Inscrits',
    path: '/business/Allinscrits',
    icon: getIcon(peopleFill)
  },
  {
    title: 'Affecter Inscrits aux formation',
    path: '/business/Affectinscrits',
    icon: getIcon(personaddfill)
  },
  {
    title: 'Vouchers',
    path: '/business/voucherBusiness',
    icon: getIcon(giftfill)
  },
  // ,
  // {
  //   title: 'Voucher de réduction',
  //   path: '/business/VoucherReduction',
  //   icon: getIcon(giftfill)
  // },


  // {
  //   title: 'Départements',
  //   path: '/business/departement',
  //   icon: getIcon(categorie)
  // },
];

export default sidebarConfigBusiness;
