// import {
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
// } from "@mui/material";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import {
//   Input,
// } from "@material-ui/core";
// import React, {  useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { AddVoucher } from "../../redux/voucherSlice";
// import axios from "axios"
// import Select from "react-select";
// import { Row, Col, FormGroup, Alert } from "reactstrap";
// import { getTypeProd } from '../../redux/typeProdSlice';
// import { getNomProp } from '../../redux/nomPropSlice';

// const configToastr = {
//   position: "top-right",
//   autoClose: 5000,
//   hideProgressBar: false,
//   newestOnTop: false,
//   closeOnClick: true,
//   rtl: false,
//   pauseOnFocusLoss: true,
//   draggable: true,
//   pauseOnHover: true,
// };

// const AffecterVoucherModal = ({ modal, toggleModal, getUsers }) => {
  
//   const [numCodes, setNumCodes] = useState("");
//   const [reduction, setReduction] = useState("");
//   const [selectedProdLabel, setSelectedProdLabel] = useState("");
//   const [selectedProd, setSelectedProd] = useState("");
//   const categorie = useSelector((state) => state.type.listTypeProd);
//   const [selectedFormation, setSelectedFormation] = useState("");

//   const [selectedPartner, setSelectedPartner] = useState("");
//   const [selectedFormateur, setSelectedFormateur] = useState("");
//   const [partners, setPartners] = useState("");
//   const [formations, setFormations] = useState([]);

//   const nom = useSelector((state) => state.nomProp.listNomProp);
//   // const formateur = useSelector((state) => state);
//   const [formateur, setformateur] = useState([]); // New state for formateurs
//   const getUserss = async () => {
//     const res = await axios.get(
//       process.env.REACT_APP_URL_BACKEND_DEV +`/formateurs`
//     );
//     setformateur(res.data.formateurs);
//   };

//   const getPartners = async () => {
//     const res = await axios.get(
//       process.env.REACT_APP_URL_BACKEND_DEV +`/partner`
//     );
//     setPartners(res.data.partners);
//   };

//   const getFormation = async () => {
//     const res = await axios.get(
//       process.env.REACT_APP_URL_BACKEND_DEV +`/formation`
//     );
//     setFormations(res.data.formations);
//   };
//   console.log("form",formations)
//   const getVouchers = async () => {
//     const res = await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/voucherr`)
   
//   };

//   useEffect(() => {
//     getUserss();
//     getPartners();
//     getFormation()
//   }, []);
//   const dispatch = useDispatch();
//   const optionsNom = [
//     { value: '', label: 'Clear' },
//     ...(Array.isArray(nom)
//       ? nom.map((categories) => ({
//           value: categories._id,
//           label: categories.titre,
//         }))
//       : [])
//   ];
//   const optionsCategorie = [
//     { value: '', label: 'Clear' },
//     ...(Array.isArray(categorie)
//       ? categorie.map((categories) => ({
//           value: categories._id,
//           label: categories.titre,
//         }))
//       : [])
//   ];
//   useEffect(() => {
//     dispatch(getTypeProd());
//     dispatch(getNomProp());

//   }, [dispatch]);
//   const handleAffecterVoucher = async () => {
//     let formData = {
//       numCodes: numCodes,
//       categorie: selectedProd,
//       reduction: reduction,
//       nom: selectedNomValue,
//       formateur: selectedNomLabel === 'Formateur' ? selectedFormateur : null,
//       partenaires: selectedNomLabel === 'Partenaires' ? selectedPartner : null,
//       // partenaires: null,

//       formation :  selectedProdLabel === 'formation' ? selectedFormation : null,
//     //  formation :  null,

//     };
  
//     dispatch(AddVoucher(formData))
//       .then((res) => {
//         toast("Affectation avec succès", {
//           position: "top-right",
//           autoClose: 3000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: false,
//           draggable: true,
//           progress: undefined,
//           type: "success",
//         });
  
//         // Clear input fields
//         setNumCodes("");
//         setReduction("");
//         setSelectedProd("");
//         setSelectedNomValue(""); // Clear selectedNomValue
//         setSelectedNomLabel(""); // Clear selectedNomLabel
//         setSelectedProdLabel(""); // Clear selectedNomLabel
//         setSelectedProd(""); // Clear selectedNomLabel

//         setSelectedFormateur(""); // Clear selectedFormateur
//         setSelectedPartner(""); // Clear selectedPartner
//       })
//       .then(() => {
//         // Close the modal after the AddVoucher action is completed
//         toggleModal();
//         getUsers(); // Assuming this function fetches users
//       });
//   };
  
//   // const handleAffecterVoucher = async () => {
//   //   let formData = {
//   //     numCodes: numCodes,
//   //     categorie: selectedProd,
//   //     reduction: reduction,
//   //     nom: selectedNomValue, // Use selectedNomValue to send to the server
//   //     formateur: selectedNomLabel === 'Formateur' ? selectedFormateur : null,
//   //     partenaires: selectedNomLabel === 'Partenaires' ? selectedPartner : null,
//   //   };

//   //   dispatch(AddVoucher(formData)).then((res) => {
//   //     toast("Affectation avec succès", {
//   //       position: "top-right",
//   //       autoClose: 3000,
//   //       hideProgressBar: false,
//   //       closeOnClick: true,
//   //       pauseOnHover: false,
//   //       draggable: true,
//   //       progress: undefined,
//   //       type: "success",
//   //     });
 
//   //   });
//   // };
  
  

  

//   const clearInputAndClose = () => {
//     setNumCodes("");
//     setReduction("");

//     toggleModal();
//     getUsers()
//   };

//   const handleChangeCategorie = async (selectedOption) => {
//     setSelectedProd(selectedOption.value);
//     setSelectedProdLabel(selectedOption.label);
//     try {
//       const res = await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/typeProd_details/${selectedOption.value}`);
//       if (selectedOption.value === 'formation') {
//         // Fetch the list of partenaires here
//         getFormation(); // Call getPartners to update the partenaires state
//       }
//     } catch (error) {
//       console.error(error);
//     }
//   };
//   const [selectedNomValue, setSelectedNomValue] = useState("");
//   const [selectedNomLabel, setSelectedNomLabel] = useState("");
//   const handleChangeNom = async (selectedOption) => {
//     setSelectedNomValue(selectedOption.value);
//     setSelectedNomLabel(selectedOption.label);
  
//     try {
//       const res = await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/nomProp_details/${selectedOption.value}`);
//       if (selectedOption.value === 'Formateur') {
//         // Fetch the list of formateurs here
//         getUserss(); // Call getUserss to update the formateurs state
//       }
//       if (selectedOption.value === 'Partenaires') {
//         // Fetch the list of partenaires here
//         getPartners(); // Call getPartners to update the partenaires state
//       }
      
//     } catch (error) {
//       console.error(error);
//     }
//   };
  

//   return (
//     <>
//       <Dialog
//         open={modal}
//         onClose={clearInputAndClose}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//       >
//         <DialogTitle id="alert-dialog-title">
//           {"Affectation Voucher"}
//         </DialogTitle>
//         <br></br>
//         <DialogContent style={{ height: "350px", width: "600px" }}>
//           <Input
//             placeholder="Choisir un nombre de voucher"
//             style={{ marginTop: "1em" }}
//             fullWidth
//             value={numCodes}
//         onChange={e => setNumCodes(e.target.value)}
          
//           />
//           <br></br>
//           <br></br> 
//                 <FormGroup>
//                   <div style={{ overflowY: 'auto' }}>
//                   <Select
//                     placeholder="Choisir type produit"
//                     options={optionsCategorie}
//                     onChange={handleChangeCategorie}

//                     menuPosition='fixed'
//                   />
//                                   </div>

//                 </FormGroup>
// <br></br>
// {selectedProdLabel === "formation" && (
//   <FormGroup>
//     <div style={{ overflowY: "auto" }}>
//       <Select
//         placeholder="Choisir formation"
//         options={formations.map(formation => ({
//           value: formation._id,
//           label: formation.titre
//         }))}
//         onChange={selectedOption => setSelectedFormation(selectedOption.value)}
//         menuPosition="fixed"
//       />
//     </div>
//   </FormGroup>
// )}
// <br></br>

//                 <FormGroup>
//                   <div style={{ overflowY: 'auto' }}>
//                   <Select
//                     placeholder="Choisir nom propriétaire"
//                     options={optionsNom}
//                     onChange={handleChangeNom}

//                     menuPosition='fixed'
//                   />
//                                   </div>

//                 </FormGroup>
//                 <br></br>
//                 {selectedNomLabel === 'Partenaires' && (
//   <FormGroup>
//     <div style={{ overflowY: 'auto' }}>
//       <Select
//         placeholder="Choisir partenaires"
//         options={partners.map(partenaire => ({ value: partenaire._id, label: partenaire.nom }))}
//         onChange={selectedOption => setSelectedPartner(selectedOption.value)}
//         menuPosition='fixed'
//       />
//     </div>
//   </FormGroup>
// )}

// {selectedNomLabel === 'Formateur' && (
//   <FormGroup>
//     <div style={{ overflowY: 'auto' }}>
//       <Select
//         placeholder="Choisir Formateur"
//         options={formateur.map(formateur => ({ 
//           value: formateur._id, 
//           label: `${formateur.nom} ${formateur.prenom}`
//         }))}
//         onChange={selectedOption => 
          
//           setSelectedFormateur(selectedOption.value)
       
//         }
//         menuPosition='fixed'
//       />
//     </div>
//   </FormGroup>
// )}

//                 <Input
//             placeholder="Reduction voucher"
//             style={{ marginTop: "1em" }}
//             fullWidth
//             value={reduction}
//         onChange={e => setReduction(e.target.value)}
          
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={clearInputAndClose}>annuler</Button>
//           <Button
//             onClick={handleAffecterVoucher}
            
//             type="button"
//           >
//             Ajouter vouchers
//           </Button>
//         </DialogActions>
//       </Dialog>
//       <ToastContainer
        
//         {...configToastr}
//       />
//     </>
//   );
// };

// export default AffecterVoucherModal;
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Input,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddVoucher } from "../../redux/voucherSlice";
import axios from "axios"
import Select from "react-select";
import { Row, Col, FormGroup, Alert } from "reactstrap";
import { getTypeProd } from '../../redux/typeProdSlice';
import { getNomProp } from '../../redux/nomPropSlice';

const configToastr = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
};

const AffecterVoucherModal = ({ modal, toggleModal, getUsers }) => {

  const [numCodes, setNumCodes] = useState("");
  const [reduction, setReduction] = useState("");
  const [selectedProdLabel, setSelectedProdLabel] = useState("");
  const [selectedProd, setSelectedProd] = useState("");
  const categorie = useSelector((state) => state.type.listTypeProd);
  const [selectedFormation, setSelectedFormation] = useState("");
  const [business, setBusiness] = useState("");
  const [selectedPartner, setSelectedPartner] = useState("");

  const [selectedBusiness, setSelectedBusiness] = useState("");
  const [selectedFormateur, setSelectedFormateur] = useState("");
  const [partners, setPartners] = useState("");
  const [formations, setFormations] = useState([]);

  const nom = useSelector((state) => state.nomProp.listNomProp);
  // const formateur = useSelector((state) => state);
  const [formateur, setformateur] = useState([]); // New state for formateurs
  const getUserss = async () => {
    const res = await axios.get(
      process.env.REACT_APP_URL_BACKEND_DEV + `/formateurs`
    );
    setformateur(res.data.formateurs);
  };

  const getPartners = async () => {
    const res = await axios.get(
      process.env.REACT_APP_URL_BACKEND_DEV + `/partner`
    );
    setPartners(res.data.partners);
  };
  const getBusiness = async () => {
    const res = await axios.get(
      process.env.REACT_APP_URL_BACKEND_DEV + `/allUsersBuisness`
    );
    setBusiness(res.data.users);
  };

  const getFormation = async () => {
    const res = await axios.get(
      process.env.REACT_APP_URL_BACKEND_DEV + `/getAllFormationsApprenant`
    );
    setFormations(res.data.formations);
  };
  console.log("form", formations)
  const getVouchers = async () => {
    const res = await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/voucherr`)

  };

  useEffect(() => {
    getUserss();
    getPartners();
    getFormation();
    getBusiness()
  }, []);
  const dispatch = useDispatch();
  const optionsNom = [
    { value: '', label: 'Clear' },
    ...(Array.isArray(nom)
      ? nom.map((categories) => ({
        value: categories._id,
        label: categories.titre,
      }))
      : [])
  ];
  const optionsCategorie = [
    { value: '', label: 'Clear' },
    ...(Array.isArray(categorie)
      ? categorie.map((categories) => ({
        value: categories._id,
        label: categories.titre,
      }))
      : [])
  ];
  useEffect(() => {
    dispatch(getTypeProd());
    dispatch(getNomProp());

  }, [dispatch]);
  const handleAffecterVoucher = async () => {
    let formData = {
      numCodes: numCodes,
      categorie: selectedProd,
      reduction: reduction,
      nom: selectedNomValue,
      formateur: selectedNomLabel === 'Formateur' ? selectedFormateur : null,
      partenairesBusiness: selectedNomLabel === 'Business' ? selectedBusiness : null,
      // partenaires: null,

      formation: selectedProdLabel === 'formation' ? selectedFormation : null,
      //  formation :  null,

    };

    dispatch(AddVoucher(formData))
      .then((res) => {
        toast("Affectation avec succès", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          type: "success",
        });

        // Clear input fields
        setNumCodes("");
        setReduction("");
        setSelectedProd("");
        setSelectedNomValue(""); // Clear selectedNomValue
        setSelectedNomLabel(""); // Clear selectedNomLabel
        setSelectedProdLabel(""); // Clear selectedNomLabel
        setSelectedProd(""); // Clear selectedNomLabel

        setSelectedFormateur(""); // Clear selectedFormateur
        setSelectedPartner(""); // Clear selectedPartner
        setSelectedBusiness("")
      })
      .then(() => {
        // Close the modal after the AddVoucher action is completed
        toggleModal();
        getUsers(); // Assuming this function fetches users
      });
  };
  const clearInputAndClose = () => {
    setNumCodes("");
    setReduction("");

    toggleModal();
    getUsers()
  };

  const handleChangeCategorie = async (selectedOption) => {
    setSelectedProd(selectedOption.value);
    setSelectedProdLabel(selectedOption.label);
    try {
      const res = await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/typeProd_details/${selectedOption.value}`);
      if (selectedOption.value === 'formation') {
        // Fetch the list of partenaires here
        getFormation(); // Call getPartners to update the partenaires state
      }
    } catch (error) {
      console.error(error);
    }
  };
  const [selectedNomValue, setSelectedNomValue] = useState("");
  const [selectedNomLabel, setSelectedNomLabel] = useState("");
  const handleChangeNom = async (selectedOption) => {
    setSelectedNomValue(selectedOption.value);
    setSelectedNomLabel(selectedOption.label);

    try {
      const res = await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/nomProp_details/${selectedOption.value}`);
      if (selectedOption.value === 'Formateur') {
        // Fetch the list of formateurs here
        getUserss(); // Call getUserss to update the formateurs state
      }
      if (selectedOption.value === 'Buisness') {
        // Fetch the list of partenaires here
        getBusiness(); // Call getPartners to update the partenaires state
      }

    } catch (error) {
      console.error(error);
    }
  };


  return (
    <>
      <Dialog
        open={modal}
        onClose={clearInputAndClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Affectation Voucher"}
        </DialogTitle>
        <br></br>
        <DialogContent style={{ height: "350px", width: "600px" }}>
          <Input
            placeholder="Choisir un nombre de voucher"
            style={{ marginTop: "1em" }}
            fullWidth
            value={numCodes}
            onChange={e => setNumCodes(e.target.value)}

          />
          <br></br>
          <br></br>
          <FormGroup>
            <div style={{ overflowY: 'auto' }}>
              <Select
                placeholder="Choisir type produit"
                options={optionsCategorie}
                onChange={handleChangeCategorie}

                menuPosition='fixed'
              />
            </div>

          </FormGroup>
          <br></br>
          {selectedProdLabel === "formation" && (
            <FormGroup>
              <div style={{ overflowY: "auto" }}>
                <Select
                  placeholder="Choisir formation"
                  options={formations.map(formation => ({
                    value: formation._id,
                    label: formation.titre
                  }))}
                  onChange={selectedOption => setSelectedFormation(selectedOption.value)}
                  menuPosition="fixed"
                />
              </div>
            </FormGroup>
          )}
          <br></br>

          <FormGroup>
            <div style={{ overflowY: 'auto' }}>
              <Select
                placeholder="Choisir nom propriétaire"
                options={optionsNom}
                onChange={handleChangeNom}

                menuPosition='fixed'
              />
            </div>

          </FormGroup>
          <br></br>
          {selectedNomLabel === 'Business' && (
            <FormGroup>
              <div style={{ overflowY: 'auto' }}>
                <Select
                  placeholder="Choisir partenaires buisness"
                  options={business.map(partenaire => ({ value: partenaire._id, label: partenaire.nom }))}
                  onChange={selectedOption => setSelectedBusiness(selectedOption.value)}
                  menuPosition='fixed'
                />
              </div>
            </FormGroup>
          )}

          {selectedNomLabel === 'Formateur' && (
            <FormGroup>
              <div style={{ overflowY: 'auto' }}>
                <Select
                  placeholder="Choisir Formateur"
                  options={formateur.map(formateur => ({
                    value: formateur._id,
                    label: `${formateur.nom} ${formateur.prenom}`
                  }))}
                  onChange={selectedOption =>

                    setSelectedFormateur(selectedOption.value)

                  }
                  menuPosition='fixed'
                />
              </div>
            </FormGroup>
          )}

          <Input
            placeholder="Reduction voucher"
            style={{ marginTop: "1em" }}
            fullWidth
            value={reduction}
            onChange={e => setReduction(e.target.value)}

          />
        </DialogContent>
        <DialogActions>
          <Button onClick={clearInputAndClose}>annuler</Button>
          <Button
            onClick={handleAffecterVoucher}

            type="button"
          >
            Ajouter vouchers
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer

        {...configToastr}
      />
    </>
  );
};

export default AffecterVoucherModal;



