import { Box, Grid, Container, Typography } from '@mui/material';
import Page from '../components/Page';
import {
  AppNewUsers,
  AppBugReports,
  AppItemOrders,
  AppWeeklySales,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppCurrentSubject,
  AppConversionRates,
  AppNewAcheteurs,
  AppApprenantsRates,
  AppNumberCertifFormations,
  AppConversionAge,
  AppProfession,
  AppConversionInscrit,
  AppCertifs,
  Combine

} from '../components/_dashboard/app';


export default function DashboardApp() {
  return (
    <Page title="Dashboard | Fastuz">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Dashboard Fastuz</Typography>
        </Box>
        <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={2}>
            <AppNewUsers />
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <AppNewAcheteurs />
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <AppWeeklySales />
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <AppBugReports />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <AppCertifs />
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <AppItemOrders />
          </Grid>
            <Grid item xs={12} md={6} lg={12}>
            <Combine />
          </Grid>
          {/* <Grid item xs={12} md={6} lg={12}>
            <AppConversionInscrit />
          </Grid> */}
          {/* <Grid item xs={12} md={6} lg={12}>
            <AppWebsiteVisits />
          </Grid> */}

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppApprenantsRates />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNumberCertifFormations />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppProfession />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppConversionAge />
          </Grid> 

          <Grid item xs={12} md={6} lg={12}>
            <AppConversionRates />
          </Grid>
       
        </Grid>

     
      </Container>
    </Page>
  );
}
