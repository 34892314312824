// import React, { useEffect, useState } from "react";
// import { CardBody, Col, Row } from "reactstrap";
// import "antd/dist/antd.css";
// import { useFormik } from "formik";
// import * as yup from "yup";
// import { useDispatch, useSelector } from "react-redux";
// import { useParams } from "react-router-dom";
// import { getFormationsById } from "../../redux/formationSlice";
// import { Stack, Container } from '@mui/material';
// import { UploadeFileAPI, modifierFormationAPI } from "../../utils/api";
// import { TextField } from "@material-ui/core";
// import Select from "react-select";
// import { CKEditor } from "ckeditor4-react";
// import FileUploader from "../../assets/FileUploader";
// import getBase64 from "../../utils/getBase64";
// import { useNavigate } from 'react-router-dom';
// import { styled } from '@mui/material/styles';
// import { LoadingButton } from '@mui/lab';
// import axios from "axios"
// import { getAllLicences } from "../../redux/formationSlice";
// import EditIcon from '@mui/icons-material/Edit';
// import { getCategorie } from "../../redux/categorieSlice";
// import Switch from '@mui/material/Switch';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import { FormGroup } from "reactstrap";
// import SweetAlert from "react-bootstrap-sweetalert";
// import Swal from "sweetalert2";
// import DatePicker, { DateObject } from "react-multi-date-picker";
// import TimePicker from "react-multi-date-picker/plugins/time_picker";
// import DatePanel from "react-multi-date-picker/plugins/date_panel";
// import { getTypeFormation } from '../../redux/typeFormationSlice';

// const ContentStyle = styled('div')(({ theme }) => ({
//   maxWidth: 950,
//   margin: 'auto',
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   padding: theme.spacing(12, 0)
// }));
// const validationSchema = yup.object({
//   titre: yup.string().required("Titre obligatoire"),
//   prix: yup.number().required("Prix obligatoire"),
//   urlMeet: yup.string()
//     .length(6, 'Code conférence doit contenir exactement 6 caractères')
//     .required('Code conférence obligatoire'),
// });
// const currencies = [
//   {
//     value: 'USD',
//     label: '$',
//   },
//   {
//     value: 'EUR',
//     label: '€',
//   },
// ];
// export default function DetailsFormations() {
//   const dispatch = useDispatch();
//   const { idFormation } = useParams();
//   const navigate = useNavigate();
//   const [cover, setCover] = useState(null);
//   const [coverPreview, setCoverPreview] = useState(null);
//   const [loading, setloading] = useState(true);
//   const userConnected = useSelector((state) => state.auth.CurrentUser);
//   const [description, setDescription] = useState("");
//   const [video, setVideo] = useState(null);
//   const [videoPreview, setVideoPreview] = useState(null);
//   const [selectedLicence, setSelectedLicence] = useState("");
//   const [selectedLicencee, setSelectedLicencee] = useState("");
//   const [selectedDuration, setselectedDuration] = useState("");
//   const [selectedDurationn, setselectedDurationn] = useState("");
//   const [logoProduit, setlogoProduit] = useState(null);
//   const [logoProduitPreview, setLogoProduitPreview] = useState(null);
//   let [showSuccess, setShowSuccess] = useState(false);
//   let [showError, setShowError] = useState(false);
//   const [selectedCategorie, setSelectedCategorie] = useState("");
//   const [selectedCategoriee, setSelectedCategoriee] = useState("");
//   const [categorie, setCategorie] = useState("")
//   const categories = useSelector((state) => state.categorie.listCategorie);
//   const [selectedTypeFormation, setSelectedTypeFormation] = useState("");
//   const [selectedTypeFormations, setSelectedTypeFormations] = useState("");
//   const [typeFormation, setTypeFormation] = useState("")
//   const typeFormations = useSelector((state) => state.TypeFormation.listTypeFormation);

//   const [formationCertifie, setFormationCertifie] = useState(false);
//   const [devise, setDevise] = useState("")
//   const [prixExterne, setPrixExterne] = useState("")
//   const [selectedDevise, setselectedDevise] = useState("");
//   const [selectedDevisee, setselectedDevisee] = useState("");
//   // const [typeFormation, setTypeFormation] = useState("");
//   const [selectedType, setselectedType] = useState("");
//   const [selectedTypee, setselectedTypee] = useState("");
//   const [dateFormation, setDateFormation] = useState("");
//   const [nombrePlace, setNombrePlace] = useState(0);

//   const [urlMeet, setUrlMeet] = useState("");
//   console.log("selectedType", categorie)
//   console.log("typeFormation", typeFormation)
//   console.log("selectedTypeFormation.label", selectedTypeFormations)


//   const handleChange = (event) => {
//     setFormationCertifie(event.target.checked);
//   };
//   const handleChangeDevise = async (selectedOption) => {
//     setselectedDevise(selectedOption.value);
//     setselectedDevisee(selectedOption);

//   };
//   const [duration, setDuration] = useState("")
//   const [licence, setLicence] = useState("")
//   const handleDescription = (e) => {
//     let data = e.editor.getData();
//     setDescription(data);
//   };
//   const licences = useSelector((state) => state.formations.listeLicences);
//   useEffect(() => {
//     dispatch(getAllLicences());
//     dispatch(getCategorie());
//     dispatch(getTypeFormation());


//   }, [dispatch]);
//   const handleChangeLicence = async (selectedOption) => {
//     setSelectedLicence(selectedOption.value);
//     setSelectedLicencee(selectedOption);
//     try {
//       const res = await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/getLicenceById/${selectedOption.value}`);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const options =

//     licences?.map((licence) => ({
//       value: licence?._id,
//       label: licence?.titre,
//     }))
//     ;
//   const handleChangeCategorie = async (selectedOption) => {
//     setSelectedCategorie(selectedOption.value);
//     setSelectedCategoriee(selectedOption);

//     try {
//       const res = await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/categorie_details/${selectedOption.value}`);
//       setCategorie(res.data.result); // Update the licence state with the fetched license information

//     } catch (error) {
//       console.error(error);
//     }
//   };
//   const optionsCategorie = categories?.map((categorie) => ({
//     value: categorie?._id,
//     label: categorie?.nom,
//   }));

//   const handleChangeTypeFormation = async (selectedOption) => {
//     setSelectedTypeFormation(selectedOption.value);
//     setSelectedTypeFormations(selectedOption);

//     try {
//       const res = await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/typeFormation_details/${selectedOption.value}`);
//       setTypeFormation(res.data.result); // Update the licence state with the fetched license information

//     } catch (error) {
//       console.error(error);
//     }
//   };
//   console.log("typeFormations", typeFormations)
//   const optionsTypeFormation = typeFormations?.map((categorie) => ({
//     value: categorie?._id,
//     label: categorie?.titre,
//   }));

//   const handleChangeDuration = async (selectedOption) => {
//     setselectedDuration(selectedOption.value);
//     setselectedDurationn(selectedOption);
//   };
//   const handleChangeType = async (selectedOption) => {
//     setselectedType(selectedOption.value);
//     setselectedTypee(selectedOption);
//   };
//   const optionsDuration = [
//     { value: '1', label: '1 ans' },
//     { value: '2', label: '2 ans' },
//     { value: '3', label: '3 ans' }
//   ]


//   const handleTypeFormationChange = (selectedOption) => {
//   };

//   useEffect(() => {
//     if (cover) getBase64(cover).then((url) => setCoverPreview(url));
//     else setCoverPreview(null);

//   }, [cover]);
//   useEffect(() => {
//     if (logoProduit) getBase64(logoProduit).then((url) => setLogoProduitPreview(url));
//     else setLogoProduitPreview(null);

//   }, [logoProduit]);
//   const [formattedDates, setFormattedDates] = useState([]);

//   useEffect(() => {
//     if (video) getBase64(video).then((url) => setVideoPreview(url));
//     else setVideoPreview(null);
//   }, [video]);
//   useEffect(() => {
//     setloading(true);
//     dispatch(getFormationsById(idFormation)).then((response) => {
//       setDescription(response.payload.description);
//       setLicence(response.payload.licence)
//       setDuration(response.payload.duration)
//       setCategorie(response.payload.categorie)
//       setFormationCertifie(response.payload.formationCertifie)
//       setPrixExterne(response.payload.prixExterne)
//       setDevise(response.payload.devise)
//       setNombrePlace(response.payload.nombrePlace)
//       setUrlMeet(response.payload.urlMeet)
//       setDateFormation(response.payload.dateFormation)
//       setTypeFormation(response.payload.typeFormation)
//       setloading(false);

//       if (response.payload.cover) setCoverPreview(response.payload.cover);
//       if (response.payload.logoProduit) setLogoProduitPreview(response.payload.logoProduit);

//       if (response.payload.videoIntro)
//         setVideoPreview(response.payload.videoIntro);
//       formik.setValues({
//         titre: response.payload?.titre,
//         prix: response.payload?.prix,
//         licence: response.payload?.licence,
//         duration: response.payload?.duration,
//         categorie: response.payload?.categorie,
//         formationCertifie: response.payload?.formationCertifie,
//         prixExterne: response.payload?.prixExterne,
//         devise: response.payload?.devise,
//         typeFormation: response.payload?.typeFormation,
//         dateFormation: response.payload?.dateFormation,
//         nombrePlace: response.payload?.nombrePlace,
//         urlMeet: response.payload?.urlMeet,
//       });
//     });
//   }, [idFormation, selectedTypee.value]);
//   console.log("optionsTypeFormation", optionsTypeFormation)
//   console.log("logoProduitPreview", logoProduitPreview)


//   useEffect(() => {
//     // Your other code logic...

//     if (Array.isArray(dateFormation)) {

//       setFormattedDates(dateFormation);
//     } else {
//       console.error('dateFormation is not an array');
//       setFormattedDates([]);
//     }
//   }, [dateFormation]); // Depend only on dateFormation

//   const formik = useFormik({
//     initialValues: {
//       titre: "",
//       prix: "",
//       licence: "",
//       duration: "",
//       categorie: "",
//       prixExterne: "",
//       devise: "",
//       nombrePlace: "",
//       urlMeet: "",
//       dateFormation: "",
//       typeFormation: ""

//     },

//     enableReinitialize: true,
//     validationSchema: validationSchema,
//     onSubmit: async (values) => {

//       const Updateobj = {
//         _id: idFormation,
//         ...values,
//         licence: selectedLicence || licence || null,
//         duration: selectedDuration || duration || null,
//         description,
//         categorie: selectedCategorie || categorie || null,
//         formationCertifie: formationCertifie,
//         formationCertifie: formationCertifie,
//         devise: selectedDevise || devise || null,
//         typeFormation: selectedTypeFormation || typeFormation || null,
//         dateFormation: formattedDates,
//         nombrePlace,
//         // urlMeet,
//         nombrePlaceRestant: nombrePlace


//       };

//       if (selectedType === 'EnLigne') {
//         Updateobj.dateFormation = null;
//         Updateobj.nombrePlace = null;
//         Updateobj.urlMeet = null;
//       }
//       if (cover) {

//         const coverRes = await UploadeFileAPI(cover, "coverFormation");
//         Updateobj.cover = coverRes.data.location;

//       }

//       if (logoProduit) {

//         const coverRes = await UploadeFileAPI(logoProduit, "logoProduit");
//         Updateobj.logoProduit = coverRes.data.location;

//       }
//       if (video) {
//         const videoRes = await UploadeFileAPI(video, "videoFormation");
//         Updateobj.videoIntro = videoRes.data.location;

//       }
//       try {
//         const response = await modifierFormationAPI(Updateobj);
//         if (response.data.ok) {
//           setShowSuccess(true);
//           console.log("success", response)
//           setTimeout(async () => {

//             if (userConnected.role === "admin") {
//               await navigate("/dashboard/formationAdmin");
//             }
//             else if (userConnected.role === "business") {
//               await navigate("/business/formations");

//             }
//             else {
//               await navigate("/formateur/formations");
//             }
//           }, 2000);
//         }
//         else {
//           setShowError(true);

//         }
//       } catch (error) {
//         Swal.fire("Un problème est survenu ! !");
//       }
//     },
//   });
//   console.log("selectedBefore", formattedDates)
//   return (
//     <div>

//       <Container maxWidth="l">
//         <ContentStyle>
//           <CardBody>
//             <Row>
//               <Stack spacing={2}>
//                 <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent="center" >

//                   <Col md={12} className="mb-5">
//                     <label><b>Couverture</b></label>

//                     {!cover && !coverPreview && (
//                       <FileUploader
//                         fileTypes={["image/png", "image/jpg", "image/jpeg"]}
//                         setFileToUpload={setCover}
//                         title="Couverture"
//                         original=""
//                       ></FileUploader>
//                     )}
//                     {coverPreview && (
//                       <div
//                         className="cover-preview"
//                         onClick={() => {
//                           setCoverPreview(null);
//                           setCover(null);
//                         }}
//                       >
//                         <br></br>
//                         <img src={cover ? coverPreview : coverPreview} width="50%" alt="" />
//                         <EditIcon />               </div>
//                     )}
//                   </Col>
//                   <Col md={12} className="mb-5">
//                     <label><b>Logo Produit</b></label>

//                     {!logoProduit && !logoProduitPreview && (
//                       <FileUploader
//                         fileTypes={["image/png", "image/jpg", "image/jpeg"]}
//                         setFileToUpload={setlogoProduit}
//                         title="Couverture"
//                         original=""
//                       ></FileUploader>
//                     )}
//                     {logoProduitPreview && (
//                       <div
//                         className="cover-preview"
//                         onClick={() => {
//                           setLogoProduitPreview(null);
//                           setlogoProduit(null);
//                         }}
//                       >
//                         <br></br>
//                         <img src={logoProduit ? logoProduitPreview : logoProduitPreview} width="50%" alt="" />
//                         <EditIcon />              </div>
//                     )}
//                   </Col>
//                   <Col md={12} className="mt-5">
//                     <label><b>Vidéo introductive</b></label>
//                     {!video && !videoPreview && (
//                       <FileUploader
//                         fileTypes={["video/mp4", "video/webm"]}
//                         setFileToUpload={setVideo}
//                         title="Vidéo introductive"
//                         original=""
//                       ></FileUploader>
//                     )}
//                     {videoPreview && (
//                       <div>
//                         <br></br>
//                         <video controls controlsList="nodownload" src={video ? videoPreview : videoPreview} width="100%"></video>
//                         <LoadingButton

//                           onClick={() => {
//                             setVideoPreview(null);
//                             setVideo(null);
//                           }}
//                         >
//                           Réinitialiser Video
//                         </LoadingButton>
//                       </div>
//                     )}
//                   </Col>
//                 </Stack>

//                 <Col md={6}>
//                   <TextField
//                     fullWidth
//                     name="titre"
//                     label="Titre"
//                     id="titre"
//                     placeholder="Titre"
//                     error={!!(formik.touched.titre && formik.errors.titre)}
//                     helperText={formik.touched.titre && formik.errors.titre}
//                     {...formik.getFieldProps("titre")}
//                   />
//                 </Col>
//                 {userConnected.role === "admin" || userConnected.role === "formateur" ? (
//                   <>

//                     <TextField
//                       style={{ marginTop: "15px" }}
//                       fullWidth
//                       type="number"
//                       name="prix"
//                       id="prix"
//                       label="Prix du formation"
//                       placeholder="10.99"
//                       error={!!(formik.touched.prix && formik.errors.prix)}
//                       helperText={formik.touched.prix && formik.errors.prix}
//                       {...formik.getFieldProps("prix")}
//                     />
//                     <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
//                       <TextField
//                         type="number"
//                         label="prix Externe"
//                         name="prixExterne"
//                         error={formik.touched.prixExterne && formik.errors.prixExterne}
//                         helperText={formik.errors.prixExterne}
//                         {...formik.getFieldProps('prixExterne')}
//                       />
//                       <FormGroup>

//                         <Select
//                           placeholder="Devise"
//                           value={
//                             selectedDevise ?
//                               { value: selectedDevisee.value, label: selectedDevisee.label }
//                               :
//                               devise
//                                 ? { value: devise, label: devise }

//                                 : null
//                           }
//                           options={currencies}
//                           onChange={handleChangeDevise}
//                         />

//                       </FormGroup>

//                     </Stack>

//                   </>)
//                   : ("")}
//                 <Col md={6}>

//                   <FormControlLabel
//                     control={
//                       <Switch
//                         checked={formationCertifie}
//                         onChange={handleChange}
//                         inputProps={{ 'aria-label': 'Formation certifiée' }}
//                       />
//                     }
//                     label="Formation certifiée"
//                     labelPlacement="start"

//                   />
//                 </Col>

//                 {userConnected.role === "admin" || userConnected.role === "formateur" ? (
//                   <>

//                     <Col md={6}>


//                       <Select
//                         placeholder="Choisir categorie"
//                         value={
//                           selectedCategorie
//                             ? { value: selectedCategoriee.value, label: selectedCategoriee.label }
//                             : categorie
//                               ? { value: categorie._id, label: categorie?.nom }
//                               : null
//                         }
//                         options={optionsCategorie}
//                         onChange={handleChangeCategorie}
//                       />

//                     </Col>

//                     <Col md={6}>

//                       <Select
//                         placeholder="Choisir Licence"
//                         value={
//                           selectedLicence
//                             ? { value: selectedLicencee.value, label: selectedLicencee.label }
//                             : licence
//                               ? { value: licence._id, label: licence?.titre }
//                               : null
//                         }
//                         options={options}
//                         onChange={handleChangeLicence}
//                       />
//                     </Col>
//                     <Col md={6}>

//                       <Select
//                         placeholder="Date d'expiration licence"
//                         value={
//                           selectedDuration ?
//                             { value: selectedDurationn.value, label: selectedDurationn.label }
//                             :
//                             duration
//                               ? { value: duration, label: duration + " ans" }

//                               : null
//                         }
//                         options={optionsDuration}
//                         onChange={handleChangeDuration}
//                       />
//                     </Col>
//                     <Select
//                       placeholder="Choisir Type Formation"
//                       value={
//                         selectedTypeFormation
//                           ? { value: selectedTypeFormations.value, label: selectedTypeFormations.label }
//                           : typeFormation
//                             ? { value: typeFormation._id, label: typeFormation?.titre }
//                             : null
//                       }
//                       options={optionsTypeFormation}
//                       onChange={handleChangeTypeFormation}
//                     />
//                     {(typeFormation?.titre === 'En ligne' || selectedTypeFormations?.label === 'En ligne') ?
//                       ("")
//                       :
//                       (typeFormation?.titre === 'Présentielle' || selectedTypeFormations?.label === 'Présentielle' || typeFormation?.titre === 'Webinaire' || selectedTypeFormations.label === 'Webinaire' || typeFormation?.titre === 'En ligne / Présentiel') ?
//                         <Col md={6}>

//                           <DatePicker
//                             value={formattedDates}
//                             onChange={(dateArray) => {
//                               // Extract the formatted date strings from the dateArray
//                               const formattedDates = dateArray?.map((dateObject) => {
//                                 return dateObject.format('DD/MM/YYYY HH:mm'); // Format date as per your requirement
//                               });

//                               setFormattedDates(formattedDates); // Update the formattedDates state
//                               console.log("formattedDatesupn", formattedDates)
//                             }}
//                             format="DD/MM/YYYY HH:mm"
//                             multiple
//                             plugins={[
//                               <TimePicker position="bottom" />,
//                               <DatePanel
//                                 markFocused
//                                 style={{ minWidth: "350px" }}
//                               />,
//                             ]}
//                           />

//                           <TextField
//                             fullWidth
//                             type="number"
//                             name="nombrePlace"
//                             id="nombrePlace"
//                             label="Nombre de place"
//                             placeholder="Nombre Place"
//                             value={nombrePlace}
//                             onChange={(e) => setNombrePlace(e.target.value)}
//                           />

//                           <TextField
//                             fullWidth
//                             name="durationFormation"
//                             label="Durée de la formation"
//                             id="durationFormation"
//                             placeholder="Durée de la formation"
//                             value={dateFormation}
//                             onChange={(e) => setDateFormation(e.target.value)}
//                           />
//                           <TextField
//                             fullWidth
//                             name="urlMeet"
//                             id="urlMeet"
//                             label="Code conférence"
//                             placeholder="Code conférence"
//                             error={!!(formik.touched.urlMeet && formik.errors.urlMeet)}
//                             helperText={formik.touched.urlMeet && formik.errors.urlMeet}
//                             {...formik.getFieldProps('urlMeet')}
//                             onChange={formik.handleChange}
//                           />
//                         </Col>
//                         : ("")
//                     }

//                   </>)
//                   : ("")}

//                 <Col md={12} className="mt-5">
//                   <label>Description</label>

//                   {!loading && (
//                     <CKEditor initData={description} onChange={handleDescription} />
//                   )}

//                 </Col>
//               </Stack>
//             </Row>

//           </CardBody>
//           <br></br>
//           <LoadingButton

//             fullWidth
//             size="medium"
//             type="button"
//             disabled={!formik.isValid}
//             variant="contained"

//             onClick={async () => {
//               await formik.handleSubmit();
//             }}
//           >

//             Confirmer la mise à jour
//           </LoadingButton>
//           <SweetAlert
//             show={showSuccess}
//             title="Formation modifiée avec succès"
//             type="success"
//             onConfirm={() => {
//               setShowSuccess(false);
//             }}
//           />

//           <SweetAlert
//             show={showError}
//             title="Un problème est survenu !"
//             type="error"
//             onConfirm={() => {
//               setShowError(false);
//             }}
//           />
//         </ContentStyle>

//       </Container>
//     </div>
//   );
// }


import React, { useEffect, useState } from "react";
import { CardBody, Col, Row } from "reactstrap";
import "antd/dist/antd.css";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getFormationsById } from "../../redux/formationSlice";
import { Stack, Container } from '@mui/material';
import { UploadeFileAPI, modifierFormationAPI } from "../../utils/api";
import { TextField } from "@material-ui/core";
import Select from "react-select";
import { CKEditor } from "ckeditor4-react";
import FileUploader from "../../assets/FileUploader";
import getBase64 from "../../utils/getBase64";
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import axios from "axios"
import { getAllLicences } from "../../redux/formationSlice";
import EditIcon from '@mui/icons-material/Edit';
import { getCategorie } from "../../redux/categorieSlice";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FormGroup } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Swal from "sweetalert2";
import DatePicker, { DateObject } from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { getTypeFormation } from '../../redux/typeFormationSlice';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 950,
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));
const validationSchema = yup.object({
  titre: yup.string().required("Titre obligatoire"),
  prix: yup.number().required("Prix obligatoire"),
  urlMeet: yup.string().when('typeFormation.titre', {
    is: 'Webinaire',
    then: yup.string()
      .length(6, 'Code conférence doit contenir exactement 6 caractères')
      .required('Required for Webinaire'),
    otherwise: yup.string().notRequired(),
  }),
});
const currencies = [
  {
    value: 'USD',
    label: '$',
  },
  {
    value: 'EUR',
    label: '€',
  },
];
export default function DetailsFormations() {
  const dispatch = useDispatch();
  const { idFormation } = useParams();
  const navigate = useNavigate();
  const [cover, setCover] = useState(null);
  const [coverPreview, setCoverPreview] = useState(null);
  const [loading, setloading] = useState(true);
  const userConnected = useSelector((state) => state.auth.CurrentUser);
  const [description, setDescription] = useState("");
  const [video, setVideo] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  const [selectedLicence, setSelectedLicence] = useState("");
  const [selectedLicencee, setSelectedLicencee] = useState("");
  const [selectedDuration, setselectedDuration] = useState("");
  const [selectedDurationn, setselectedDurationn] = useState("");
  const [logoProduit, setlogoProduit] = useState(null);
  const [logoProduitPreview, setLogoProduitPreview] = useState(null);
  let [showSuccess, setShowSuccess] = useState(false);
  let [showError, setShowError] = useState(false);
  const [selectedCategorie, setSelectedCategorie] = useState("");
  const [selectedCategoriee, setSelectedCategoriee] = useState("");
  const [categorie, setCategorie] = useState("")
  const categories = useSelector((state) => state.categorie.listCategorie);
  const [selectedTypeFormation, setSelectedTypeFormation] = useState("");
  const [selectedTypeFormations, setSelectedTypeFormations] = useState("");
  const [typeFormation, setTypeFormation] = useState("")
  const typeFormations = useSelector((state) => state.TypeFormation.listTypeFormation);

  const [formationCertifie, setFormationCertifie] = useState(false);
  const [devise, setDevise] = useState("")
  const [prixExterne, setPrixExterne] = useState("")
  const [selectedDevise, setselectedDevise] = useState("");
  const [selectedDevisee, setselectedDevisee] = useState("");
  // const [typeFormation, setTypeFormation] = useState("");
  const [selectedType, setselectedType] = useState("");
  const [selectedTypee, setselectedTypee] = useState("");
  const [dateFormation, setDateFormation] = useState("");
  const [nombrePlace, setNombrePlace] = useState(0);

  const [urlMeet, setUrlMeet] = useState("");
  console.log("selectedType", urlMeet)



  const handleChange = (event) => {
    setFormationCertifie(event.target.checked);
  };
  const handleChangeDevise = async (selectedOption) => {
    setselectedDevise(selectedOption.value);
    setselectedDevisee(selectedOption);

  };
  const [duration, setDuration] = useState("")
  const [licence, setLicence] = useState("")
  const handleDescription = (e) => {
    let data = e.editor.getData();
    setDescription(data);
  };
  const licences = useSelector((state) => state.formations.listeLicences);
  useEffect(() => {
    dispatch(getAllLicences());
    dispatch(getCategorie());
    dispatch(getTypeFormation());


  }, [dispatch]);
  const handleChangeLicence = async (selectedOption) => {
    setSelectedLicence(selectedOption.value);
    setSelectedLicencee(selectedOption);
    try {
      const res = await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/getLicenceById/${selectedOption.value}`);
    } catch (error) {
      console.error(error);
    }
  };

  const options =

    licences?.map((licence) => ({
      value: licence?._id,
      label: licence?.titre,
    }))
    ;
  const handleChangeCategorie = async (selectedOption) => {
    setSelectedCategorie(selectedOption.value);
    setSelectedCategoriee(selectedOption);

    try {
      const res = await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/categorie_details/${selectedOption.value}`);
      setCategorie(res.data.result); // Update the licence state with the fetched license information

    } catch (error) {
      console.error(error);
    }
  };
  const optionsCategorie = categories?.map((categorie) => ({
    value: categorie?._id,
    label: categorie?.nom,
  }));

  const handleChangeTypeFormation = async (selectedOption) => {
    setSelectedTypeFormation(selectedOption.value);
    setSelectedTypeFormations(selectedOption);

    try {
      const res = await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/typeFormation_details/${selectedOption.value}`);
      setTypeFormation(res.data.result); // Update the licence state with the fetched license information

    } catch (error) {
      console.error(error);
    }
  };
  const optionsTypeFormation = typeFormations?.map((categorie) => ({
    value: categorie?._id,
    label: categorie?.titre,
  }));

  const handleChangeDuration = async (selectedOption) => {
    setselectedDuration(selectedOption.value);
    setselectedDurationn(selectedOption);
  };
  const optionsDuration = [
    { value: '1', label: '1 ans' },
    { value: '2', label: '2 ans' },
    { value: '3', label: '3 ans' }
  ]


  const handleTypeFormationChange = (selectedOption) => {
  };
  useEffect(() => {
    if (cover) getBase64(cover).then((url) => setCoverPreview(url));
    else setCoverPreview(null);

  }, [cover]);
  useEffect(() => {
    if (logoProduit) getBase64(logoProduit).then((url) => setLogoProduitPreview(url));
    else setLogoProduitPreview(null);

  }, [logoProduit]);
  const [formattedDates, setFormattedDates] = useState([]);

  useEffect(() => {
    if (video) getBase64(video).then((url) => setVideoPreview(url));
    else setVideoPreview(null);
  }, [video]);
  useEffect(() => {
    setloading(true);
    dispatch(getFormationsById(idFormation)).then((response) => {
      setDescription(response.payload.description);
      setLicence(response.payload.licence)
      setDuration(response.payload.duration)
      setCategorie(response.payload.categorie)
      setFormationCertifie(response.payload.formationCertifie)
      setPrixExterne(response.payload.prixExterne)
      setDevise(response.payload.devise)
      setNombrePlace(response.payload.nombrePlace)
      setUrlMeet(response.payload.urlMeet)
      setDateFormation(response.payload.dateFormation)
      setTypeFormation(response.payload.typeFormation)
      setloading(false);

      if (response.payload.cover) setCoverPreview(response.payload.cover);
      if (response.payload.logoProduit) setLogoProduitPreview(response.payload.logoProduit);

      if (response.payload.videoIntro)
        setVideoPreview(response.payload.videoIntro);
      formik.setValues({
        titre: response.payload?.titre,
        prix: response.payload?.prix,
        licence: response.payload?.licence,
        duration: response.payload?.duration,
        categorie: response.payload?.categorie,
        formationCertifie: response.payload?.formationCertifie,
        prixExterne: response.payload?.prixExterne,
        devise: response.payload?.devise,
        typeFormation: response.payload?.typeFormation,
        dateFormation: response.payload?.dateFormation,
        nombrePlace: response.payload?.nombrePlace,
        urlMeet: response.payload?.urlMeet,
      });
    });
  }, [idFormation, selectedTypee.value]);
  console.log("optionsTypeFormation", optionsTypeFormation)
  console.log("logoProduitPreview", logoProduitPreview)


  useEffect(() => {
    // Your other code logic...

    if (Array.isArray(dateFormation)) {

      setFormattedDates(dateFormation);
    } else {
      console.error('dateFormation is not an array');
      setFormattedDates([]);
    }
  }, [dateFormation]); // Depend only on dateFormation

  const formik = useFormik({
    initialValues: {
      titre: "",
      prix: "",
      licence: "",
      duration: "",
      categorie: "",
      prixExterne: "",
      devise: "",
      nombrePlace: "",
      urlMeet: "",
      dateFormation: "",
      typeFormation: ""

    },

    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {

      const Updateobj = {
        _id: idFormation,
        ...values,
        licence: selectedLicence || licence || null,
        duration: selectedDuration || duration || null,
        description,
        categorie: selectedCategorie || categorie || null,
        formationCertifie: formationCertifie,
        formationCertifie: formationCertifie,
        devise: selectedDevise || devise || null,
        typeFormation: selectedTypeFormation || typeFormation || null,
        dateFormation: formattedDates,
        nombrePlace,
        // urlMeet,
        nombrePlaceRestant: nombrePlace


      };

      if (selectedType === 'EnLigne') {
        Updateobj.dateFormation = null;
        Updateobj.nombrePlace = null;
        Updateobj.urlMeet = null;
      }
      if (cover) {

        const coverRes = await UploadeFileAPI(cover, "coverFormation");
        Updateobj.cover = coverRes.data.location;

      }

      if (logoProduit) {

        const coverRes = await UploadeFileAPI(logoProduit, "logoProduit");
        Updateobj.logoProduit = coverRes.data.location;

      }
      if (video) {
        const videoRes = await UploadeFileAPI(video, "videoFormation");
        Updateobj.videoIntro = videoRes.data.location;

      }
      try {
        const response = await modifierFormationAPI(Updateobj);
        if (response.data.ok) {
          setShowSuccess(true);
          console.log("success", response)
          setTimeout(async () => {

            if (userConnected.role === "admin") {
              await navigate("/dashboard/formationAdmin");
            }
            else if (userConnected.role === "business") {
              await navigate("/business/formations");

            }
            else {
              await navigate("/formateur/formations");
            }
          }, 2000);
        }
        else {
          setShowError(true);

        }
      } catch (error) {
        Swal.fire("Un problème est survenu ! !");
      }
    },
  });
  console.log("selectedBefore", formattedDates)
  return (
    <div>

      <Container maxWidth="l">
        <ContentStyle>
          <CardBody>
            <Row>
              <Stack spacing={2}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent="center" >

                  <Col md={12} className="mb-5">
                    <label><b>Couverture</b></label>

                    {!cover && !coverPreview && (
                      <FileUploader
                        fileTypes={["image/png", "image/jpg", "image/jpeg"]}
                        setFileToUpload={setCover}
                        title="Couverture"
                        original=""
                      ></FileUploader>
                    )}
                    {coverPreview && (
                      <div
                        className="cover-preview"
                        onClick={() => {
                          setCoverPreview(null);
                          setCover(null);
                        }}
                      >
                        <br></br>
                        <img src={cover ? coverPreview : coverPreview} width="50%" alt="" />
                        <EditIcon />               </div>
                    )}
                  </Col>
                  <Col md={12} className="mb-5">
                    <label><b>Logo Produit</b></label>

                    {!logoProduit && !logoProduitPreview && (
                      <FileUploader
                        fileTypes={["image/png", "image/jpg", "image/jpeg"]}
                        setFileToUpload={setlogoProduit}
                        title="Couverture"
                        original=""
                      ></FileUploader>
                    )}
                    {logoProduitPreview && (
                      <div
                        className="cover-preview"
                        onClick={() => {
                          setLogoProduitPreview(null);
                          setlogoProduit(null);
                        }}
                      >
                        <br></br>
                        <img src={logoProduit ? logoProduitPreview : logoProduitPreview} width="50%" alt="" />
                        <EditIcon />              </div>
                    )}
                  </Col>
                  <Col md={12} className="mt-5">
                    <label><b>Vidéo introductive</b></label>
                    {!video && !videoPreview && (
                      <FileUploader
                        fileTypes={["video/mp4", "video/webm"]}
                        setFileToUpload={setVideo}
                        title="Vidéo introductive"
                        original=""
                      ></FileUploader>
                    )}
                    {videoPreview && (
                      <div>
                        <br></br>
                        <video controls controlsList="nodownload" src={video ? videoPreview : videoPreview} width="100%"></video>
                        <LoadingButton

                          onClick={() => {
                            setVideoPreview(null);
                            setVideo(null);
                          }}
                        >
                          Réinitialiser Video
                        </LoadingButton>
                      </div>
                    )}
                  </Col>
                </Stack>

                <Col md={6}>
                  <TextField
                    fullWidth
                    name="titre"
                    label="Titre"
                    id="titre"
                    placeholder="Titre"
                    error={!!(formik.touched.titre && formik.errors.titre)}
                    helperText={formik.touched.titre && formik.errors.titre}
                    {...formik.getFieldProps("titre")}
                  />
                </Col>
                {userConnected.role === "admin" || userConnected.role === "formateur" ? (
                  <>

                    <TextField
                      style={{ marginTop: "15px" }}
                      fullWidth
                      type="number"
                      name="prix"
                      id="prix"
                      label="Prix du formation"
                      placeholder="10.99"
                      error={!!(formik.touched.prix && formik.errors.prix)}
                      helperText={formik.touched.prix && formik.errors.prix}
                      {...formik.getFieldProps("prix")}
                    />
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      <TextField
                        type="number"
                        label="prix Externe"
                        name="prixExterne"
                        error={formik.touched.prixExterne && formik.errors.prixExterne}
                        helperText={formik.errors.prixExterne}
                        {...formik.getFieldProps('prixExterne')}
                      />
                      <FormGroup>

                        <Select
                          placeholder="Devise"
                          value={
                            selectedDevise ?
                              { value: selectedDevisee.value, label: selectedDevisee.label }
                              :
                              devise
                                ? { value: devise, label: devise }

                                : null
                          }
                          options={currencies}
                          onChange={handleChangeDevise}
                        />

                      </FormGroup>

                    </Stack>

                  </>)
                  : ("")}
                <Col md={6}>

                  <FormControlLabel
                    control={
                      <Switch
                        checked={formationCertifie}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'Formation certifiée' }}
                      />
                    }
                    label="Formation certifiée"
                    labelPlacement="start"

                  />
                </Col>

                {userConnected.role === "admin" || userConnected.role === "formateur" ? (
                  <>

                    <Col md={6}>


                      <Select
                        placeholder="Choisir categorie"
                        value={
                          selectedCategorie
                            ? { value: selectedCategoriee.value, label: selectedCategoriee.label }
                            : categorie
                              ? { value: categorie._id, label: categorie?.nom }
                              : null
                        }
                        options={optionsCategorie}
                        onChange={handleChangeCategorie}
                      />

                    </Col>

                    <Col md={6}>

                      <Select
                        placeholder="Choisir Licence"
                        value={
                          selectedLicence
                            ? { value: selectedLicencee.value, label: selectedLicencee.label }
                            : licence
                              ? { value: licence._id, label: licence?.titre }
                              : null
                        }
                        options={options}
                        onChange={handleChangeLicence}
                      />
                    </Col>
                    <Col md={6}>

                      <Select
                        placeholder="Date d'expiration licence"
                        value={
                          selectedDuration ?
                            { value: selectedDurationn.value, label: selectedDurationn.label }
                            :
                            duration
                              ? { value: duration, label: duration + " ans" }

                              : null
                        }
                        options={optionsDuration}
                        onChange={handleChangeDuration}
                      />
                    </Col>
                    <Select
                      placeholder="Choisir Type Formation"
                      value={
                        selectedTypeFormation
                          ? { value: selectedTypeFormations.value, label: selectedTypeFormations.label }
                          : typeFormation
                            ? { value: typeFormation._id, label: typeFormation?.titre }
                            : null
                      }
                      options={optionsTypeFormation}
                      onChange={handleChangeTypeFormation}
                    />
                    {(typeFormation?.titre === 'En ligne' || selectedTypeFormations?.label === 'En ligne') ?
                      ("")
                      :
                      (typeFormation?.titre === 'Présentielle' || selectedTypeFormations?.label === 'Présentielle' || typeFormation?.titre === 'Webinaire' || selectedTypeFormations.label === 'Webinaire') ?
                        <Col md={6}>

                          <DatePicker
                            value={formattedDates}
                            onChange={(dateArray) => {
                              // Extract the formatted date strings from the dateArray
                              const formattedDates = dateArray?.map((dateObject) => {
                                return dateObject.format('DD/MM/YYYY HH:mm'); // Format date as per your requirement
                              });

                              setFormattedDates(formattedDates); // Update the formattedDates state
                              console.log("formattedDatesupn", formattedDates)
                            }}
                            format="DD/MM/YYYY HH:mm"
                            multiple
                            plugins={[
                              <TimePicker position="bottom" />,
                              <DatePanel
                                markFocused
                                style={{ minWidth: "350px" }}
                              />,
                            ]}
                          />

                          <TextField
                            fullWidth
                            type="number"
                            name="nombrePlace"
                            id="nombrePlace"
                            label="Nombre de place"
                            placeholder="Nombre Place"
                            value={nombrePlace}
                            onChange={(e) => setNombrePlace(e.target.value)}
                          />

                          <TextField
                            fullWidth
                            name="durationFormation"
                            label="Durée de la formation"
                            id="durationFormation"
                            placeholder="Durée de la formation"
                            value={dateFormation}
                            onChange={(e) => setDateFormation(e.target.value)}
                          />
                          <TextField
                            fullWidth
                            name="urlMeet"
                            id="urlMeet"
                            label="Code conférence"
                            placeholder="Code conférence"
                            error={!!(formik.touched.urlMeet && formik.errors.urlMeet)}
                            helperText={formik.touched.urlMeet && formik.errors.urlMeet}
                            {...formik.getFieldProps('urlMeet')}
                            onChange={formik.handleChange}
                          />
                        </Col>
                        : ("")
                    }

                  </>)
                  : ("")}

                <Col md={12} className="mt-5">
                  <label>Description</label>

                  {!loading && (
                    <CKEditor initData={description} onChange={handleDescription} />
                  )}

                </Col>
              </Stack>
            </Row>

          </CardBody>
          <br></br>
          <LoadingButton

            fullWidth
            size="medium"
            type="button"
            disabled={!formik.isValid}
            variant="contained"

            onClick={async () => {
              await formik.handleSubmit();
            }}
          >

            Confirmer la mise à jour
          </LoadingButton>
          <SweetAlert
            show={showSuccess}
            title="Formation modifiée avec succès"
            type="success"
            onConfirm={() => {
              setShowSuccess(false);
            }}
          />

          <SweetAlert
            show={showError}
            title="Un problème est survenu !"
            type="error"
            onConfirm={() => {
              setShowError(false);
            }}
          />
        </ContentStyle>

      </Container>
    </div>
  );
}
















