import { useState, useEffect } from "react";
import axios from "axios";

// material
import {

  Button,
  Container,
  FormGroup,

} from "@mui/material";
import Select from "react-select";

// components
import Page from "../../components/Page.js";
import moment from "moment";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';


import { CardBody, Col, Row } from "reactstrap";
import ReactTable from "react-table-v6";

import { Stack, Typography } from "@mui/material";
import ImportFileBusiness  from "./ImportFileBusiness.js";
import Cookies from 'js-cookie';



export default function UserBusiness() {

  const [loading, setLoading] = useState(false);
  const [apprenants, setApprenants] = useState([]);
  const [formations, setFormations] = useState([]);

  const [open, setOpen] = useState(false);
  const token= Cookies.get("tokenAdmin")
  const[listFormations, setListFormations]= useState([])
  const [selectedFormation, setSelectedFormation] = useState({
    value: null,
    label: "All formations"
  });
  
  const [selectedDept, setSelectedDept] = useState(null);

  const handleFormationChange = (selectedOption) => {
    setSelectedFormation(selectedOption);
  };


  useEffect(() => {
    getApprenants();
    getMoreData();
  }, [selectedFormation]);

    const getMoreData = async () => {
   
      await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/getAllFormationsFormateur`, 
        {
          headers: { Authorization: `Bearer ${token}`, 
          withCredentials: true
        },
      })
        .then(response => {
  setListFormations(response.data.formations)  
  console.log("form",response)

    })
        .catch(error => {
          console.error(error);
        });
    }

  const handleClose = () => {
    setOpen(false);
  };


  const getApprenants = async () => {
    setLoading(true);
    try {
      let res;
      const headers = { Authorization: `Bearer ${token}` };
      const url = process.env.REACT_APP_URL_BACKEND_DEV;
  
      if (selectedFormation.value === null) {
        res = await axios.get(`${url}/getAllApprenantsLists`, {
          headers,
          withCredentials: true,
        });
        console.log("Response for all apprenants", res.data);
  
        if (Array.isArray(res.data.data)) {
          const flattenedData = res.data.data.flatMap(({ formation, departement, listeInscrit }) => {
            if (Array.isArray(listeInscrit)) {
              return listeInscrit.map(apprenant => ({
                formation: formation?.titre || "Unknown",
                departement: departement?.titre || "Unknown",
                nom: apprenant?.nom || "Unknown",
                prenom: apprenant?.prenom || "Unknown",
                email: apprenant?.email || "Unknown",
                dateInscrit: apprenant?.createdAt || "Unknown",
              }));
            }
            return [];
          });
          const sortedApprenants = flattenedData.sort((a, b) => new Date(a.dateInscrit) - new Date(b.dateInscrit));
          setApprenants(sortedApprenants);
          console.log("Flattened and sorted data for all apprenants", sortedApprenants);
        } else {
          setApprenants([]);
          console.log("Data is not an array for all apprenants");
        }
      } else {
        res = await axios.get(`${url}/getApprenantsByFormation/${selectedFormation.value}`, {
          headers,
          withCredentials: true,
        });
        console.log(`Response for formation ${selectedFormation.value}`, res.data);
  
        if (Array.isArray(res.data.data.listeInscrit)) {
          const flattenedData = res.data.data.listeInscrit.map(apprenant => ({
            nom: apprenant?.nom || "Unknown",
            prenom: apprenant?.prenom || "Unknown",
            email: apprenant?.email || "Unknown",
            dateInscrit: apprenant?.createdAt || "Unknown",
          }));
          const sortedApprenants = flattenedData.sort((a, b) => new Date(a.dateInscrit) - new Date(b.dateInscrit));
          setApprenants(sortedApprenants);
          console.log(`Flattened and sorted data for formation ${selectedFormation.value}`, sortedApprenants);
        } else {
          setApprenants([]);
          console.log(`Data is not an array for formation ${selectedFormation.value}`);
        }
      }
    } catch (error) {
      console.error('Error fetching apprenants:', error);
      setApprenants([]);
    } finally {
      setLoading(false);
    }
  };
  
  console.log("apprenants", apprenants);
  

  
  const columns = [
 
    {
      Header: "Nom et Prénom",
      id: "nomPrenom",
      accessor: row => `${row.nom} ${row.prenom}`,
      filterMethod: (filter, row) =>
        row.nomPrenom.toLowerCase().includes(filter.value.toLowerCase()),
      Cell: ({ original }) => {
        return (
          <div>
            <span style={{ wordBreak: "break-all" }}>
              {original.nom} {original.prenom}
            </span>
          </div>
        );
      },
    },     
    
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ original }) => {
        return <div>{original?.email} </div>
      },

    },

    {
      Header: "Date d'inscription",
      accessor: "dateInscrit",
      width: 150,
      Cell: ({ value }) => {
        return (
          <span style={{ wordBreak: "break-all" }}>
            {moment(value).locale("fr").format("DD/MM/YYYY")}
          </span>
        );
      },
      filterMethod: (filter, row) => {
        const date = moment(row[filter.id]).format("DD/MM/YYYY");
        return date.includes(filter.value);
      },
      defaultSortDesc: true,

    },
  ];
  
  

  return (
    <Page title="Dashboard | Fastuz">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
          Inscrits 
          </Typography>
      
         

        </Stack>

        <Select
          placeholder="Choisir Formation"
          value={selectedFormation}
          onChange={(selectedOption) => setSelectedFormation(selectedOption)}
          options={[
            { value: null, label: "Toutes les formations"}, // Add an option for All
            ...(listFormations?.map((formation) => ({
              value: formation._id,
              label: formation.titre,
            })) || []),
          ]}
        />
        
        <br></br>
        <CardBody>
          <Row>

            <ReactTable
              data={apprenants}
              nextText={"Suivant"}
              previousText={"Précedent"}
              rowsText={"Lignes"}
              columns={columns}
              defaultPageSize={10}
              filterable={true}
              noDataText="No data is available"
              defaultSorted={[{id :"createdAt" , desc : true}]}

            />


          </Row>
        </CardBody>
     
      </Container>
    </Page>
  );
}

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { Button, Container, FormGroup, Typography, Stack } from "@mui/material";
// import Select from "react-select";
// import Dialog from '@mui/material/Dialog';
// import DialogContent from '@mui/material/DialogContent';
// import DialogTitle from '@mui/material/DialogTitle';
// import { CardBody, Row } from "reactstrap";
// import ReactTable from "react-table-v6";
// import moment from "moment";
// import Cookies from 'js-cookie';
// import Page from "../../components/Page.js";
// import ImportFileBusiness from "./ImportFileBusiness.js";

// export default function UserBusiness() {
//   const [loading, setLoading] = useState(false);
//   const [apprenants, setApprenants] = useState([]);
//   const [listFormations, setListFormations] = useState([]);
//   const [selectedFormation, setSelectedFormation] = useState({ value: null, label: "All" });
//   const [selectedDept, setSelectedDept] = useState(null);
//   const [open, setOpen] = useState(false);
//   const token = Cookies.get("token");

//   useEffect(() => {
//     getApprenants();
//     getFormations();
//   }, [selectedFormation]);

//   const getFormations = async () => {
//     try {
//       const response = await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/getAllFormationsFormateur`, {
//         headers: { Authorization: `Bearer ${token}` },
//         withCredentials: true
//       });
//       setListFormations(response.data.formations);
//     } catch (error) {
//       console.error('Error fetching formations:', error);
//     }
//   }

//   const getApprenants = async () => {
//     setLoading(true);
//     try {
//       let url = selectedFormation.value === null ? '/getAllApprenantsLists' : `/getApprenantsByFormation/${selectedFormation.value}`;
//       const response = await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + url, {
//         headers: { Authorization: `Bearer ${token}` },
//         withCredentials: true
//       });
//       if (Array.isArray(response.data.data)) {
//         const flattenedData = response.data.data.flatMap(item => {
//           const formation = item.formation ? item.formation.titre : null;
//           return item.listeInscrit.map(apprenant => ({
//             formation,
//             nom: apprenant.nom,
//             prenom: apprenant.prenom,
//             email: apprenant.email,
//             dateInscrit: apprenant.createdAt
//           }));
//         });
//         const sortedApprenants = flattenedData.sort((a, b) => new Date(a.dateInscrit) - new Date(b.dateInscrit));
//         setApprenants(sortedApprenants);
//       } else {
//         setApprenants([]);
//       }
//     } catch (error) {
//       console.error('Error fetching apprenants:', error);
//       setApprenants([]);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   const columns = [
//     {
//       Header: "Nom et Prénom",
//       id: "nomPrenom",
//       accessor: row => `${row.nom} ${row.prenom}`,
//       filterMethod: (filter, row) => row.nomPrenom.toLowerCase().includes(filter.value.toLowerCase()),
//       Cell: ({ original }) => (
//         <div>
//           <span style={{ wordBreak: "break-all" }}>{original.nom} {original.prenom}</span>
//         </div>
//       ),
//     },
//     {
//       Header: "Email",
//       accessor: "email",
//       Cell: ({ original }) => <div>{original?.email}</div>,
//     },
//     {
//       Header: "Date d'inscription",
//       accessor: "dateInscrit",
//       width: 150,
//       Cell: ({ value }) => <span style={{ wordBreak: "break-all" }}>{moment(value).locale("fr").format("DD/MM/YYYY")}</span>,
//       filterMethod: (filter, row) => moment(row[filter.id]).format("DD/MM/YYYY").includes(filter.value),
//       defaultSortDesc: true,
//     },
//   ];

//   return (
//     <Page title="Dashboard | Fastuz">
//       <Container>
//         <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
//           <Typography variant="h4" gutterBottom>
//             Inscrits
//           </Typography>
//         </Stack>

//         <Select
//           placeholder="Choisir Formation"
//           value={selectedFormation}
//           onChange={(selectedOption) => setSelectedFormation(selectedOption)}
//           options={[
//             { value: null, label: "All" },
//             ...(listFormations?.map((formation) => ({ value: formation._id, label: formation.titre })) || []),
//           ]}
//         />

//         <CardBody>
//           <Row>
//             <ReactTable
//               data={apprenants}
//               nextText={"Suivant"}
//               previousText={"Précedent"}
//               rowsText={"Lignes"}
//               columns={columns}
//               defaultPageSize={10}
//               filterable={true}
//               noDataText="No data is available"
//               defaultSorted={[{ id: "dateInscrit", desc: true }]}
//             />
//           </Row>
//         </CardBody>

//         <Dialog
//           open={open}
//           onClose={handleClose}
//           aria-labelledby="alert-dialog-title"
//           aria-describedby="alert-dialog-description"
//         >
//           <DialogTitle id="alert-dialog-title">{"Ajouter un fichier Excel"}</DialogTitle>
//           <DialogContent>
//             <br /><br /><br />
//             <ImportFileBusiness handleClose={handleClose} selectedFormation={selectedFormation} selectedDept={selectedDept} getApprenants={getApprenants} />
//           </DialogContent>
//         </Dialog>
//       </Container>
//     </Page>
//   );
// }
